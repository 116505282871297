@mixin activity-editor {
  &-activity-editor {
    &-container {
      column-gap: $size-3;
      height: 100%;
    }

    &-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
    }

    &-settings-container,
    &-wrapper {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-radius: $content-border-radius;
      background-color: $tt9-color-neutral-0;
    }

    &-settings {
      height: 100%;
      padding: $size-3 0 0;
      color: $tt9-color-neutral-500;
      overflow: hidden;

      &-container {
        position: relative;
        width: 440px; // for large screen
        box-sizing: border-box;
        height: 100%;
      }

      &-footer {
        border-radius: 0 0 $content-border-radius $content-border-radius;
        border: 1px solid $tt9-color-neutral-100;
      }

      &-content {
        padding: 0 $size-3;
        flex: 1;
        overflow: auto;
      }
    }

    &-options {
      padding: 0 0 $size-3;
    }

    &-wrapper {
      flex: 1;
      overflow: hidden;

      .title {
        padding: $size-3 $size-3 0;
        margin-bottom: $size-4;
      }
    }

    &-actions {
      button {
        margin: $size-3 0;
        justify-content: center;
      }
    }

    &-settings-footer {
      button {
        justify-content: center;
      }
    }

    &-item {
      &-container {
        padding: $size-3;
        border-radius: $size-1;
        background-color: $tt9-color-neutral-0;
        box-sizing: border-box;
      }

      &-header {
        display: flex;
        height: $size-4;
        justify-content: space-between;
        align-items: center;
        position: relative;
      }

      &-sequence {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $size-4;
        min-width: $size-3;
        border-radius: calc($size-4 / 2);
        background-color: $tt9-color-neutral-50;
        padding: 0 $size-1;
      }

      &-menu-active {
        color: $tt9-color-primary-300;
      }

      &-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $size-4;
        height: $size-4;
        margin-left: auto;
      }

      &-wrapper {
        margin-bottom: $size-3;
        border: $size-2 solid $tt9-color-neutral-50;
        border-radius: $size-2;
        box-sizing: border-box;
        max-width: 100%;

        &:hover:not(.disabled),
        &.focused:not(.disabled) {
          border-color: $tt9-color-primary-100;
        }
      }

      &-wrapper:hover:not(.disabled) &-sequence,
      &-wrapper.focused:not(.disabled) &-sequence {
        background-color: $tt9-color-primary-100;
      }

      &-drag {
        &-placeholder {
          height: $size-3;
          background-color: $tt9-color-primary-100;
          border-radius: $size-2;

          &-container {
            margin: $size-3;
          }
        }

        &-handler {
          position: absolute;
          left: calc(50% - 24px);
        }
      }
    }
  }
}
