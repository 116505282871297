@mixin controls {
  &-checkbox {
    &.mat-primary {
      .mdc-form-field {
        align-items: center !important;
      }

      .mdc-checkbox {
        height: 18px;
        width: 18px;

        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      &.mat-checkbox {
        font-size: 14px;
        color: $color-neutral-700;

        .mat-checkbox-frame {
          border-color: $color-neutral-700;
        }

        .mdc-label {
          line-height: 20px;
          color: $color-neutral-900;
        }

        &.mdc-checkbox--selected {
          .mdc-checkbox__background {
            background-color: $color-secondary;
          }
        }

        &.mat-mdc-checkbox-disabled {
          color: $color-neutral-600;

          .mat-checkbox-frame {
            border-color: $color-neutral-600;
          }

          .mdc-label {
            color: $color-neutral-700;
          }

          &.mdc-checkbox--selected {
            .mdc-checkbox__background {
              background-color: $color-neutral-600;
            }
          }
        }
      }

      &:hover {
        .mat-checkbox-frame {
          border-color: $color-secondary;
        }
      }
    }
  }

  &-checkbox-background {
    &.mat-primary {
      &.mat-checkbox {
        background-color: $color-neutral-100;
      }
    }
  }

  &-radio {
    &.mat-mdc-radio-button {
      .mdc-radio__outer-circle {
        border-color: $color-neutral-700;
      }

      &.mat-primary {
        &.mat-mdc-radio-checked {
          .mdc-radio__outer-circle {
            border-color: $color-secondary-500;
          }

          .mdc-radio__inner-circle {
            background-color: $color-secondary-500;
            height: 16px;
            left: 2px;
            top: 2px;
            width: 16px;
          }
        }

        .mdc-radio {
          &:hover {
            .mdc-radio__outer-circle {
              border-color: $color-secondary-500;
            }
          }
        }

        &.mdc-radio--disabled {
          .mdc-radio__outer-circle {
            border-color: $color-neutral-600;
          }

          .mdc-radio__inner-circle {
            background-color: $color-neutral-600;
          }
        }
      }
    }
  }

  &-toggle {
    &.mat-slide-toggle {
      .mat-slide-toggle-bar {
        height: 12px;
        background-color: $tt9-color-neutral-300;
      }

      .mat-slide-toggle-thumb {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        background-color: $color-neutral-200;

        &-container {
          top: -4px;
        }
      }

      &.mat-primary {
        &.mat-checked {
          .mat-slide-toggle-bar {
            background-color: $color-secondary-200;
          }

          .mat-slide-toggle-thumb {
            background-color: $color-secondary;
          }
        }
      }
    }
  }

  &-slider {
    &.mat-slider-horizontal {
      max-width: 992px;
      width: 100%;
      height: 35px;
    }

    .mat-slider-wrapper {
      .mat-slider-thumb,
      .mat-slider-thumb-label,
      .mat-slider-track-fill {
        background-color: $color-secondary !important;
      }

      .mat-slider-track-wrapper {
        .mat-slider-track-background {
          background-color: $color-secondary-200;
        }
      }

      .mat-slider-thumb {
        transform: scale(0) !important;

        &-label {
          transform: rotate(45deg) !important;
          border-radius: 50% 50% 0 !important;
          display: block !important;

          &-text {
            transform: rotate(-45deg) !important;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1 !important;
          }
        }
      }
    }

    &.mat-slider-disabled {
      .mat-slider-wrapper {
        background-color: $color-neutral-700 !important;
        opacity: 0.38;

        .mat-slider-thumb-label {
          background-color: $color-neutral-700 !important;
        }

        .mat-slider-track-wrapper {
          display: none;
        }
      }
    }
  }

  &-mat-error {
    @extend .assistive;
    color: $color-error !important;
    margin-top: 8px;
  }

  &-labels {
    @extend .assistive;
    color: $color-neutral-700;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    text-transform: uppercase;
  }
}
