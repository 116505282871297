@mixin chip {
  &-chip {
    .mdc-evolution-chip-set__chips {
      margin: -$size-1 !important;
    }

    .inactive {
      .mat-ripple-element {
        background-color: transparent !important;
      }
    }

    .mat-mdc-chip {
      display: flex !important;
      align-items: center !important;
      padding: 8px 16px !important;
      height: 32px !important;
      width: fit-content;
      background-color: $tt9-color-primary-50 !important;
      border-radius: 100px !important;
      box-sizing: border-box;
      white-space: nowrap !important;
      transition: all 0.3s;
      max-width: calc(100% - 8px) !important;
      margin: $size-1 !important;
      --mdc-chip-focus-state-layer-color: unset !important;

      @include font-style(14px, 0px, 18px, 500);
      color: $tt9-color-primary !important;

      &:not(.disabled):not(:disabled) {
        &:hover {
          background-color: $tt9-color-primary-100 !important;
          cursor: pointer !important;

          .mat-mdc-chip-remove {
            color: $tt9-color-primary-200 !important;
          }

          .mdc-evolution-chip__text-label {
            color: $tt9-color-primary !important;
            cursor: pointer !important;
          }
        }

        &:active,
        &.selected {
          background-color: $tt9-color-primary !important;

          .mdc-evolution-chip__text-label {
            color: $tt9-color-neutral-0 !important;
          }

          .mat-mdc-chip-remove {
            color: $tt9-color-neutral-0 !important;
          }
        }
      }

      &:disabled,
      &.disabled,
      &.disabled accenture-icon {
        padding-left: 4px;
        background-color: $tt9-color-neutral-50 !important;
        border-color: $tt9-color-neutral-400 !important;

        .mdc-evolution-chip__text-label {
          color: $tt9-color-neutral-400 !important;
        }

        .color-error,
        .color-success {
          color: $tt9-color-neutral-400 !important;
        }
      }

      &:not(.disabled):not(:disabled).inactive {
        &:hover {
          background-color: $tt9-color-primary-50 !important;
          cursor: initial !important;

          .mdc-evolution-chip__text-label {
            cursor: initial !important;
          }
        }

        &:active {
          background-color: $tt9-color-primary-50 !important;
          cursor: initial !important;

          .mat-ripple-element {
            background-color: transparent !important;
          }

          .mdc-evolution-chip__text-label {
            color: $tt9-color-primary !important;
          }
        }
      }

      .mat-mdc-chip-remove {
        width: 24px !important;
        height: 24px !important;
        margin-right: -8px !important;
        margin-left: 8px !important;
        color: $tt9-color-primary-200 !important;
        opacity: 1 !important;
      }

      span {
        text-overflow: ellipsis;
      }

      &.mat-mdc-chip {
        box-shadow: none !important;

        &::after {
          display: none;
        }

        &:not(.mdc-evolution-chip--disabled) {
          .mdc-evolution-chip {
            &__text-label {
              @include font-style(14px, 0px, 18px, 500);
              color: $tt9-color-primary;
              display: flex;
            }
          }
        }

        .mdc-evolution-chip {
          &__cell {
            overflow: hidden;
          }

          &__cell--trailing {
            overflow: visible;
          }

          &__action {
            padding: 0 !important;
            overflow: hidden;
          }

          &__text-label {
            font-family: $tt9-font-primary !important;
            overflow: hidden;

            span {
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
