@import "tt9-theme/variables";

@mixin userpic-dimensions($width: $size-5, $height: $size-5) {
  width: $width !important;
  height: $height !important;

  img {
    width: $width !important;
    height: $width !important;
    object-fit: cover;
  }
}

@mixin userpic {
  &-userpic {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: $tt9-color-primary-300;
    color: $tt9-color-neutral-0;
    flex-shrink: 0;

    &:not(.large, .compact) {
      text-transform: uppercase;
    }

    &:not(.small):not(.large, .compact):not(.team-member) {
      @include userpic-dimensions;
      @extend .tt9-body-2;
    }

    &.large {
      @include userpic-dimensions(128px, 128px);
      @extend .tt9-h3;
    }

    &.compact {
      @include userpic-dimensions(64px, 64px);
      @extend .tt9-h3;
    }

    &.medium {
      @include userpic-dimensions(40px, 40px);
      @extend .tt9-body-2;
    }

    &.normal {
      min-height: $size-5;
      min-width: $size-5;

      @include userpic-dimensions($size-5, $size-5);
      @extend .tt9-body-2;
    }

    &.small {
      min-height: $size-4;
      min-width: $size-4;

      @include userpic-dimensions($size-4, $size-4);
      @extend .tt9-tooltip;
    }

    &.team-member {
      @include userpic-dimensions(80px, 80px);
      @extend .tt9-h3;
    }
  }
}
