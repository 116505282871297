@mixin navigation-icon {
  color: $tt9-color-neutral-600;
  height: 24px;
  width: 24px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
  flex-shrink: 0;

  &.small {
    height: 16px;
    width: 16px;
  }

  &.medium {
    height: 40px;
    width: 40px;
  }
}

@mixin navigation {
  &-navigation-container {
    padding: $size-2;
    display: flex;
    flex-direction: column;

    .navigation-item-activity-container {
      padding: $size-2;

      .navigation-item-head {
        margin-bottom: $size-2;
        text-transform: uppercase;
      }
    }

    .navigation-item-activity {
      @include font-style(16px, 0, 24px, 400);
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: $size-2;

      .navigation-icons-container {
        @include screen-all-desktops {
          gap: $size-2;
        }
      }

      .navigation-icon {
        @include navigation-icon;
      }

      .navigation-hover-icon {
        display: none;

        @include screen-all-mobiles {
          display: flex;
        }
      }

      &.hovered .navigation-hover-icon,
      &:hover .navigation-hover-icon {
        display: flex;
      }
    }

    .navigation-item-label,
    .navigation-item-name {
      flex-shrink: 0;
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      overflow-wrap: anywhere;

      @include screen-all-mobiles {
        white-space: normal;
      }

      // fix double tooltip in Safari
      &::after {
        content: "";
        display: block;
      }
    }

    .navigation-item-label {
      color: $tt9-color-neutral-500;
      //tt9-caption-3
      @include font-style(10px, 0px, 14px, 500);
      text-transform: uppercase;
    }

    .navigation-item-name {
      //tt9-body-medium-2
      @include font-style(14px, 0px, 18px, 500);
    }

    .navigation-item-activity {
      padding: 7px;
      border: 1px solid $tt9-color-neutral-200;
      border-radius: $border-radius;
      flex-wrap: wrap;
      display: flex;
      align-items: flex-start;
      gap: $size-2;
      margin-bottom: $size-2;
      color: $tt9-color-neutral;

      @include screen-all-desktops {
        &.hovered:not(.hidden),
        &:hover:not(.hidden) {
          background-color: $tt9-color-primary-50;
          border-color: $tt9-color-primary-100;
        }
      }

      &.focused:not(.expanded),
      &.focused:not(.expanded):hover {
        padding: $size-2;
        border: none;
        background-color: map-get($tt9-accent-colors, 10, 1);
        color: $tt9-color-neutral-0;

        .navigation-icon,
        .navigation-hover-icon,
        .navigation-item-label {
          color: $tt9-color-neutral-0;
        }

        &.selected,
        &.selected:hover {
          border: 2px solid $tt9-color-success-500;
        }
      }

      &.hidden:not(.selected),
      &.hidden:not(.selected):hover {
        background-color: $tt9-color-neutral-50;
        color: $tt9-color-neutral-300;
      }

      &.selected,
      &.selected:hover {
        padding: 6px;
        border: 2px solid $tt9-color-primary-300;
        background-color: $tt9-color-primary-50;
      }
    }

    .navigation-item-actions {
      display: flex;
      gap: $size-2;
      width: 100%;
      align-items: center;
    }

    .navigation-item-label-container {
      flex-grow: 1;
    }

    .navigation-activity-items {
      display: flex;
      flex-direction: column;

      &-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: $size-2;
      }

      &-icon {
        align-items: center;

        @include screen-all-mobiles {
          margin: 4px 0 0 4px;
          align-items: start;
        }
      }

      .navigation-activity-item {
        box-sizing: border-box;
        border-radius: $size-1;
        display: flex;
        width: 100%;
        padding: $size-1 $size-2;
        align-items: center;
        color: $tt9-color-neutral;

        @include screen-all-mobiles {
          min-height: 40px;
          padding: $size-2;
        }

        &:not(:last-child) {
          margin-bottom: $size-1;
        }

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          flex-grow: 1;
        }

        &.selected,
        &:hover {
          background-color: $tt9-color-primary-100;
        }

        &.focused {
          @include screen-all-mobiles {
            padding: 2px $size-2;
          }
        }

        &.focused,
        &.focused:hover {
          background-color: map-get($tt9-accent-colors, 10, 1);
          color: $tt9-color-neutral-0;

          &.selected,
          &.selected:hover {
            outline: 2px solid $tt9-color-success-500;
          }

          .navigation-icon {
            color: $tt9-color-neutral-0;
          }
        }
      }
    }
  }

  @include screen-all-mobiles {
    &-navigation-container {
      padding: 0 $size-2;
    }
  }
}
