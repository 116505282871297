@mixin side-panel {
  &-side-panel {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-container {
      background-color: $tt9-color-neutral-0;
      box-shadow: 0 2.4px 7.4px rgba(0, 0, 0, 0.18), 0 12.8px 28.8px rgba(0, 0, 0, 0.22);
      height: 100vh;
      margin-top: auto;
      right: 0 !important;
      bottom: 0 !important;
      left: auto !important;
    }

    &-header {
      border-bottom: 1px solid $tt9-color-neutral-200;
      padding: $size-3;
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }

    &-body {
      flex-grow: 1;
      overflow-y: auto;
    }
  }

  &-floating-side-panel {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-container {
      background-color: $tt9-color-neutral-0;
      box-shadow: 0 2.4px 7.4px rgba(0, 0, 0, 0.18), 0 12.8px 28.8px rgba(0, 0, 0, 0.22);
      height: 100vh;
      margin-top: auto;
      right: 0 !important;
      bottom: 0 !important;
      left: auto !important;
      margin-right: $size-4;
      margin-bottom: $size-4;
      border-radius: $size-2;
    }

    &-header {
      border-bottom: 1px solid $tt9-color-neutral-200;
      padding: $size-3;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding: $size-2 $size-3;
      border-top-left-radius: $size-2;
      border-top-right-radius: $size-2;
    }

    &-body {
      flex-grow: 1;
      overflow-y: auto;
    }
  }
}
