@mixin button {
  border-radius: $border-radius !important;
  font-family: $font-primary;
  padding: 7px 19px;
  border: 1px solid transparent;
  transition: all 0.3s;
  display: flex;

  @include font-style(14px, 0.25px, 24px, 500);
  text-align: center;
  white-space: nowrap;

  &.full-width {
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  &:not(:disabled):not(.disabled):hover {
    cursor: pointer;
  }

  mat-icon {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@mixin button-filled($button-color: $color-primary-shades, $primary-shade: "", $hover-shade: 800, $active-shade: 900) {
  @include button;
  background-color: map-get($button-color, $primary-shade);
  border-color: map-get($button-color, $primary-shade);
  color: $color-neutral-100;

  &:hover:not(:disabled) {
    background-color: map-get($button-color, $hover-shade);
    border-color: map-get($button-color, $hover-shade);
  }

  &:active:not(:disabled) {
    background-color: map-get($button-color, $active-shade);
    border-color: map-get($button-color, $active-shade);
  }

  &:disabled {
    background-color: $color-neutral-600;
    border-color: $color-neutral-600;
    color: $color-neutral-700;
  }
}

@mixin button-outline($button-color: $color-primary-shades, $primary-shade: "", $hover-shade: 200, $active-shade: 900) {
  @include button;
  background-color: map-get($button-color, $primary-shade);
  border-color: map-get($button-color, $primary-shade);
  color: $color-primary-900;

  mat-icon {
    color: $color-primary-900;
  }

  &:hover:not(.disabled):not(:disabled) {
    background-color: map-get($button-color, $hover-shade);
    border-color: map-get($button-color, $hover-shade);
  }

  &:active:not(.disabled):not(:disabled) {
    background-color: map-get($button-color, $active-shade);
    border-color: map-get($button-color, $active-shade);
    color: $color-neutral-100;

    mat-icon {
      color: $color-neutral-100;
    }
  }

  &.disabled,
  &:disabled {
    background-color: $color-neutral-500;
    border-color: $color-neutral-500;
    color: $color-neutral-100;

    mat-icon {
      color: $color-neutral-100;
    }
  }
}

@mixin button-ghost($button-color: $color-primary-shades, $primary-shade: "", $hover-shade: 800, $active-shade: 900) {
  @include button;
  padding-left: 4px;
  padding-right: 4px;
  background-color: transparent;
  border-color: transparent;
  font-weight: 400 !important;
  color: map-get($button-color, $primary-shade) !important;

  mat-icon {
    color: map-get($button-color, $primary-shade) !important;
  }

  &:hover:not(.disabled):not(:disabled) {
    color: map-get($button-color, $hover-shade) !important;

    mat-icon {
      color: map-get($button-color, $hover-shade) !important;
    }
  }

  &:active:not(.disabled):not(:disabled) {
    color: map-get($button-color, $active-shade) !important;

    mat-icon {
      color: map-get($button-color, $active-shade) !important;
    }
  }

  &.disabled,
  &:disabled {
    color: $color-neutral-600 !important;

    mat-icon {
      color: $color-neutral-600 !important;
    }
  }
}
