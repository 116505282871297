@mixin drag-placeholder {
  &-drag-placeholder {
    display: block;
    background-color: $color-secondary-100;
    border: 1px solid $color-secondary-500;
    height: 108px;
    width: 100%;
    border-radius: $border-radius;
    box-sizing: border-box;

    &-container {
      padding: 8px;
      max-width: $question-panel-max-width; // TODO: refactor
    }

    // TODO: refactor
    &-response-container {
      width: 100%;
    }
  }

  &-drag-container {
    position: relative;
    max-width: $question-panel-max-width;
    padding: 8px;

    .drag-handler {
      display: none;
      position: absolute;
      top: 8px;
      z-index: 2;
      left: calc(50% - 12px);
      transform: rotate(90deg);
      cursor: move;
    }

    &:hover {
      & > .drag-handler {
        display: block;
      }
    }
  }
}
