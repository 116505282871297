$size-1: 4px;
$size-2: $size-1 * 2; // 8px
$size-3: $size-1 * 4; // 16px
$size-4: $size-1 * 6; // 24px
$size-5: $size-1 * 8; // 32px
$size-6: $size-1 * 12; // 48px
$size-7: $size-1 * 14; // 56px
$size-8: $size-1 * 16; // 64px
$size-9: $size-1 * 18; // 72px
$size-10: $size-1 * 20; // 80px
$size-11: $size-1 * 22; // 88px
$size-12: $size-1 * 24; // 96px
