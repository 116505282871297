@import "reset-css/_reset";

@import "variables";
@import "typography";
@import "text-enhancements";
@import "utils";
@import "vendor/vendor";
@import "fonts/fontello/css/fontello.css";
@import "fonts/graphik/graphik.css";
@import "fonts/twemoji/twemoji.css";
@import "tt9-theme/variables";
@import "responsive";

// Accenture Theme
.accenture {
  @import "accenture-theme/theme";

  @include buttons;
  @include controls;
  @include category-chip;
  @include navigation-card;
  @include navigation-list;
  @include breadcrumbs;
  @include drag-placeholder;
  @include drag-left-placeholder;
  @include drag-preview;
  @include tabs;
  @include dialog;
  @include activity-view;
  @include files-list;
  @include table;
  @include primary-tooltip;
  @include tooltip;
  @include tooltip-large;
  @include chart;
}

.tt9 {
  @import "tt9-theme/theme";

  @include content;
  @include modal;

  @include typography;
  @include navigation;
  @include buttons;
  @include slider;
  @include table;
  @include select-table;
  @include team-table;
  @include breadcrumbs;
  @include tools-panel;
  @include attribute-chip;
  @include checkbox;
  @include toggle;
  @include radio-button;
  @include invisible-input;
  @include chart;
  @include word-cloud;
  @include navigation-card;
  @include snack-bar;
  @include side-panel;
  @include form-fields;
  @include chip;
  @include userpic;
  @include activity-editor;
  @include navigation-tab;
  @include arrow-tooltip;
  @include menu;
  @include files-list;
  @include overlay-input;
  @include tabs;
  @include badges;
  @include dnd;
  @include skeleton;
}

// global styles for select arrow rotation
.mat-mdc-select[aria-expanded="true"] {
  .mat-mdc-select-arrow-wrapper {
    .mat-mdc-select-arrow {
      transform: rotate(180deg) !important;
    }
  }
}

// global styles for mat-autocomplete
.mat-mdc-autocomplete-panel {
  padding: 0 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  background: $tt9-color-neutral-0;

  .mat-mdc-option {
    font-family: inherit;
    letter-spacing: 0;
    color: $tt9-color-neutral;
  }

  .mat-mdc-option:hover:not(.mat-option-disabled),
  .mat-option:focus:not(.mat-option-disabled) {
    background: $tt9-color-neutral-50;
  }
}

// global styles for mat-calendar (all browsers)
.mat-calendar-body tr {
  overflow: hidden;
  height: 40px;
  white-space: nowrap;

  td:not(.mat-calendar-body-label) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

// snack-bars should be on top of modals
.cdk-overlay-container:has(.mdc-snackbar) {
  z-index: 1001 !important;
}

.cdk-overlay-container:has(.loader-container) {
  z-index: 1001 !important;
}

// global styles for the snack bar when uploading a presentation
.cdk-overlay-container:has(.upload-file-warning-snack-bar) {
  .cdk-overlay-pane {
    position: absolute !important;
    bottom: calc(50% - 343px) !important; // 343px height of snackbar + margin + 50% of heigh of fsp-modal
  }
}

.mat-mdc-form-field-hint-spacer {
  display: none !important;
}

.mat-mdc-form-field-bottom-align::before {
  content: none !important;
}
