@import "mixins/snackbar";

@mixin snack-bar {
  &-snack-bar-notification,
  &-snack-bar,
  &-snack-bar-success,
  &-snack-bar-error,
  &-snack-bar-warning {
    margin: 0 !important;

    .mdc-snackbar__surface {
      @include snackbar($tt9-color-warning-100, 396px);

      .mat-mdc-snack-bar-label {
        padding: 0 !important;
        color: $tt9-color-neutral;
        font-family: $tt9-font-primary;
      }
    }
  }

  &-snack-bar-notification {
    margin: 0 !important;

    .mdc-snackbar {
      &__surface {
        @include snackbar($tt9-color-neutral-100, 396px);
        right: 40px !important;
        margin-top: $header-height + $header-offset + $header-margin + $size-3 !important;
        margin-right: $view-padding + $content-toolbar-width + $content-toolbar-gap + $size-4 !important;
      }
    }

    &-icon {
      @include snackbar-icon($tt9-color-neutral-700);
    }
  }

  &-snack-bar {
    .mdc-snackbar {
      &__surface {
        @include snackbar($tt9-color-accent-100, 396px);
      }
    }

    &-icon {
      @include snackbar-icon($tt9-color-accent-500);
    }
  }

  &-snack-bar-success {
    .mdc-snackbar {
      &__surface {
        @include snackbar($tt9-color-success-100, 396px);
      }
    }

    &-icon {
      @include snackbar-icon($tt9-color-success-500);
    }
  }

  &-snack-bar-error {
    .mdc-snackbar {
      &__surface {
        @include snackbar($tt9-color-error-50, 396px);
      }
    }

    &-icon {
      @include snackbar-icon($tt9-color-error);
    }
  }

  &-snack-bar-warning {
    .mdc-snackbar {
      &__surface {
        @include snackbar($tt9-color-warning-100, 396px);
      }
    }

    &-icon {
      @include snackbar-icon($tt9-color-warning, $tt9-color-neutral);
    }
  }

  &-snack-bar-warning-wide {
    .mdc-snackbar {
      &__surface {
        @include snackbar($tt9-color-warning-100, 750px);
        margin: $size-3 0 !important;
        padding: 0 !important;
      }
    }

    &-icon {
      @include snackbar-icon($tt9-color-warning, $tt9-color-neutral);
    }

    &.mat-mdc-snack-bar-container .mdc-snackbar__label {
      color: $tt9-color-neutral;
    }
  }

  &-snack-bar-list {
    list-style: disc inside;
  }

  @include screen-all-handset {
    &-snack-bar-notification .mdc-snackbar__surface {
      @include snackbar($tt9-color-accent-100, 288px);
    }

    &-snack-bar .mdc-snackbar__surface {
      @include snackbar($tt9-color-accent-100, 288px);
    }

    &-snack-bar-success .mdc-snackbar__surface {
      @include snackbar($tt9-color-success-100, 288px);
    }

    &-snack-bar-error .mdc-snackbar__surface {
      @include snackbar($tt9-color-error-50, 288px);
    }

    &-snack-bar-warning .mdc-snackbar__surface {
      @include snackbar($tt9-color-warning-100, 288px);
    }

    &-snack-bar-warning-wide .mdc-snackbar__surface {
      @include snackbar($tt9-color-warning-100, 288px);
    }
  }
}
