@import "tt9-theme/variables";
@import "@ctrl/ngx-emoji-mart/picker";

.linkified {
  @extend .body;
  color: $color-secondary;
}

.fsp-picker-holder .fsp-picker {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}

.emoji-menu {
  .emoji-picker {
    &:hover {
      color: $tt9-color-primary-300 !important;
    }
  }

  .emoji-mart {
    border: 1px solid $tt9-color-neutral-100;
    border-radius: $size-1;
    max-width: 255px;
    position: relative;

    &-category-label {
      font-size: 12px;
    }

    &-search {
      input {
        border-color: $tt9-color-primary !important;
        color: $tt9-color-neutral-800;
        font-size: 12px;
      }
    }

    &-skin-swatches {
      border-color: $tt9-color-neutral-200;
      cursor: pointer;
    }

    &-category {
      .emoji-mart-emoji {
        span {
          cursor: pointer;
          height: 18px !important;
          width: 18px !important;
        }

        &:hover {
          &::before {
            background-color: $tt9-color-neutral-100;
            border-radius: $size-1;
          }
        }
      }
    }

    &-bar {
      border-color: $tt9-color-secondary-50;
    }

    &-scroll {
      height: 200px;
      margin: $size-1 $size-1 0 0;
    }

    &-anchors {
      svg,
      img {
        height: 12px;
        width: 12px;
      }
    }

    &-preview {
      height: $size-10 !important;
    }
  }
}
