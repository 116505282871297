@mixin menu {
  &-menu.mat-mdc-menu-panel {
    border-radius: $border-radius !important;
    box-shadow: 0 $size-2 $size-3 rgba(0, 0, 0, 0.18) !important;
    border: 1px solid $tt9-color-neutral-50 !important;
    max-width: none !important;
    min-height: auto !important;
    margin-top: $size-3 !important;
    background: $tt9-color-neutral-0;
    position: relative;

    .mat-mdc-menu-content {
      overflow-x: hidden !important;
      padding: $size-2;
    }

    &.with-divider {
      .mat-mdc-menu-content {
        padding: $size-2 0;
      }
    }

    .mat-mdc-menu-item {
      .mat-mdc-menu-item-text {
        @extend .tt9-body-1;
        font-family: $tt9-font-primary !important;
        display: flex;
        align-items: center;
      }

      height: 40px;
      font-family: $tt9-font-primary;
      padding: $size-2;
      margin: 0 !important;
      display: flex !important;
      align-items: center !important;
      justify-content: flex-start !important;
      border-radius: 0 !important;
      background: transparent;
      min-height: auto !important;

      &:not(:disabled):not(.btn-error):not(.color-error) {
        color: $tt9-color-neutral !important;
      }

      &:not(:disabled).color-error {
        color: $tt9-color-error !important;
      }

      &:hover {
        background-color: $tt9-color-neutral-50 !important;
        border-radius: $border-radius !important;

        &:not(:disabled):not(.btn-error):not(.color-error) {
          color: $tt9-color-neutral !important;
        }
      }

      a {
        color: $tt9-color-neutral;
        text-decoration: none;
      }

      .fontello-icon {
        margin-right: $size-2 !important;
      }

      &.active {
        color: $tt9-color-primary-300 !important;

        .mat-mdc-menu-item-text {
          color: $tt9-color-primary-300 !important;
        }

        .fontello-icon {
          color: $tt9-color-primary-300 !important;
        }
      }

      &.disable,
      &[disabled] {
        color: $tt9-color-neutral-300 !important;
        opacity: initial;

        .fontello-icon {
          color: $tt9-color-neutral-300 !important;
        }
      }

      &:not(:hover).cdk-program-focused {
        background-color: transparent;
      }
    }

    .menu-divider {
      padding: $size-2;
      margin: $size-2 0;
      border-bottom: 1px solid $tt9-color-neutral-200;
      border-top: 1px solid $tt9-color-neutral-200;

      &.only-top {
        border-bottom-width: 0;
      }
    }
  }
}
