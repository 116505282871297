@import "mixins/button";
@import "responsive";

@mixin buttons {
  &-btn {
    @include button;
    @include button-filled($tt9-color-primary-shades, 300);

    &.btn-icon {
      @include button-icon;
    }

    &.btn-rounded {
      border-radius: 20px;
    }

    &.btn-accent {
      @include button-filled($tt9-color-accent-shades, 300);
    }

    &.btn-success {
      @include button-filled($tt9-color-success-shades, 500, 600, 600);
    }

    &.btn-warn {
      @include button-filled($tt9-color-warning-shades, 300);
    }

    &.btn-error {
      @include button-filled($tt9-color-error-shades, 300);
    }

    &.btn-neutral {
      @include button-filled($tt9-color-neutral-shades, 900);
    }

    &.btn-with-border {
      @include font-style(14px, 0.3px, 20px, 500);
      padding: 14px 16px;
      border-radius: 100px;
      border: $border-radius solid $tt9-color-neutral-50;
      max-height: 64px;

      &.with-icon {
        i {
          margin-right: 8px;
        }
      }

      &.large {
        @include font-style(20px, 0.3px, 24px, 500);
        @extend .d-flex-center;

        max-height: 80px;
      }
    }

    &.btn-medium-size {
      padding: 7px 23px;
      @include font-style(14px, 0.3px, 24px, 500);
      min-height: 60px;
      min-width: 95px;
      align-items: center;
      justify-content: center;
    }

    &.btn-large-size {
      @include font-style(20px, 0.25px, 24px, 500);
      min-height: 80px;
      min-width: 156px;
      align-items: center;
      justify-content: center;
    }
  }

  &-btn-default {
    @include button;
    @include button-filled-secondary($tt9-color-primary-shades, 300);

    &.btn-icon {
      @include button-icon;
    }

    &.btn-rounded {
      border-radius: 20px;
    }

    &.btn-accent {
      @include button-filled-secondary($tt9-color-secondary-shades, 300);
    }

    &.btn-success {
      @include button-filled-secondary($tt9-color-success-shades, 600, 75, 500, $tt9-color-success-100);
    }

    &.btn-warn {
      @include button-filled-secondary($tt9-color-warning-shades, 300);
    }

    &.btn-error {
      @include button-filled-secondary($tt9-color-error-shades, 300);
    }

    &.btn-error-light {
      @include button-filled-secondary($tt9-color-error-shades, 500, 75, 75, $tt9-color-error-50);
    }
  }

  &-btn-outline {
    @include button;
    @include button-outline($tt9-color-primary-shades);

    &.btn-icon {
      @include button-icon;
    }

    &.btn-rounded {
      border-radius: 20px;
    }

    &.btn-accent {
      @include button-outline($tt9-color-secondary-shades);
    }

    &.btn-success {
      @include button-outline($tt9-color-success-shades);
    }

    &.btn-warn {
      @include button-outline($tt9-color-warning-shades);
    }

    &.btn-error {
      @include button-outline($tt9-color-error-shades);
    }
  }

  &-btn-ghost {
    @include button;
    @include button-text;
    @include button-ghost($tt9-color-primary-shades);

    &.btn-icon {
      @include button-icon;
      @include font-style(16px, 0px, 20px, 400);

      &:hover:not(.disabled):not(:disabled) {
        color: map-get($tt9-color-primary-shades, 400);
      }
    }

    &.btn-accent {
      @include button-ghost($tt9-color-accent-shades);
    }

    &.btn-secondary {
      @include button-ghost($tt9-color-secondary-shades);
    }

    &.btn-success {
      @include button-ghost($tt9-color-success-shades);
    }

    &.btn-warn {
      @include button-ghost($tt9-color-warning-shades);
    }

    &.btn-error {
      @include button-ghost($tt9-color-error-shades);
    }

    &.btn-neutral {
      @include button-ghost($tt9-color-neutral-shades, 500);
    }

    &.btn-medium {
      @include font-style(16px, 0px, 24px, 400);
    }

    &.btn-large {
      @include font-style(20px, 0px, 24px, 400);
    }
  }

  &-btn-ghost-primary {
    @include button-ghost($tt9-color-primary-shades, 900, 800, 900);
    padding-left: 0;
    padding-right: 0;
  }

  &-btn-ghost-danger {
    @include button-ghost($tt9-color-error-shades, 500);
    padding-left: 0;
    padding-right: 0;
  }

  &-btn-ghost-success {
    @include button-ghost($tt9-color-success-shades, 700);
    padding-left: 0;
    padding-right: 0;
  }

  &-btn-ghost-neutral {
    @include button-ghost($tt9-color-neutral-shades, 700, 800, 900);
    padding-left: 0;
    padding-right: 0;
  }

  &-btn-icon {
    @include button-icon;
    @include button-filled-secondary($tt9-color-primary-shades, 300);
    color: $tt9-color-neutral-500;
    background-color: transparent !important;
    border: 0 !important;
    padding: 8px !important;

    &.btn-accent {
      @include button-ghost($tt9-color-secondary-shades, 300);
    }

    &.btn-success {
      @include button-ghost($tt9-color-success-shades, 300);
    }

    &.btn-warn {
      @include button-ghost($tt9-color-warning-shades, 300);
    }

    &.btn-error {
      @include button-ghost($tt9-color-error-shades, 300);
    }

    &.btn-without-padding {
      padding: 0 !important;
    }

    &.btn-without-border {
      border: none !important;
    }
  }

  &-btn-icon-filled {
    @include button-icon;
    @include button-filled-secondary($tt9-color-primary-shades, 300);
    color: $tt9-color-neutral-500;

    &.btn-rounded {
      border-radius: 20px;
    }

    &.btn-primary {
      @include button-filled($tt9-color-primary-shades, 300);
    }

    &.btn-accent {
      @include button-filled-secondary($tt9-color-secondary-shades, 300);
    }

    &.btn-success {
      @include button-filled-secondary($tt9-color-success-shades, 300);
    }

    &.btn-warn {
      @include button-filled-secondary($tt9-color-warning-shades, 300);
    }

    &.btn-error {
      @include button-filled-secondary($tt9-color-error-shades, 300);
    }

    &.btn-dark {
      @include button-filled-secondary($tt9-color-neutral-shades, 500, 100, 200, $tt9-color-neutral-900);
    }

    &.btn-medium-size {
      min-height: 60px;
      min-width: 60px;
      align-items: center;
      justify-content: center;
    }

    &.btn-medium-small-size {
      min-height: 48px;
      min-width: 48px;
      align-items: center;
      justify-content: center;
    }
  }

  &-image-input-close-btn {
    @include button;
    @include button-filled($tt9-color-neutral-shades, 900, 700, 700);

    @include screen-all-handset {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border-radius: $border-radius-small;
      height: 40px;
      width: 40px;
    }
  }

  &-arrow-btn {
    @include button-icon;
    background-color: $tt9-color-neutral-200;
    border-color: $tt9-color-neutral-50;
    color: $tt9-color-neutral-0;

    &.with-border {
      border-radius: 20px;
    }

    &:not(.with-border) {
      border-radius: 18px;
      padding: 5px;
      border: none;
    }

    &:hover:not(:disabled) {
      background-color: $tt9-color-primary-100;
      border-color: $tt9-color-primary-100;
      color: $tt9-color-neutral-0;
    }

    &:active:not(:disabled) {
      background-color: $tt9-color-primary-200;
      border-color: $tt9-color-primary-200;
      color: $tt9-color-neutral-0;
    }

    &:disabled {
      background-color: $tt9-color-neutral-100;
      border-color: $tt9-color-neutral-100;
      color: $tt9-color-neutral-300;
    }

    &.active {
      background-color: $tt9-color-primary-300;
      border-color: $tt9-color-primary-300;
      color: $tt9-color-neutral-0;
    }
  }

  &-neutral-btn {
    @include button;
    @include button-filled($tt9-color-neutral-shades, 50, 200, 200);
    color: $tt9-color-primary;

    &.btn-icon {
      @include button-icon;
    }
  }

  &-snack-bar {
    &-confirm-btn {
      @include button;
      @include button-filled($tt9-color-accent-shades, 300, 200, 200);
      color: $tt9-color-neutral-0;

      &:hover {
        color: $tt9-color-accent-400;
      }
    }

    &-action-btn {
      @include button;
      @include button-ghost($tt9-color-neutral-shades, 0, 0, 0);
      color: $tt9-color-neutral-0;
    }

    &-action-btn-info {
      @include button;
      @include button-filled($tt9-color-neutral-shades, 0, 0, 0, $tt9-color-accent-700);

      &:hover {
        background-color: $tt9-color-primary-100 !important;
      }
    }

    &-action-btn-success {
      @include button;
      @include button-filled($tt9-color-neutral-shades, 0, 0, 0, $tt9-color-success-600);

      &:hover {
        background-color: $tt9-color-success-75 !important;
      }
    }

    &-action-btn-error {
      @include button;
      @include button-filled($tt9-color-neutral-shades, 0, 0, 0, $tt9-color-error-500);

      &:hover {
        background-color: $tt9-color-error-100 !important;
      }
    }

    &-action-btn-warning {
      @include button;
      @include button-filled($tt9-color-neutral-shades, 0, 0, 0, $tt9-color-neutral);

      &:hover {
        background-color: $tt9-color-warning-200 !important;
      }
    }

    &-action-btn-notification {
      @include button;
      @include button-filled($tt9-color-neutral-shades, 0, 200, 0, $tt9-color-neutral);
    }

    &-accent-btn {
      @include button;
      @include button-filled($tt9-color-accent-shades, 500, 500, 500);
    }

    &-btn-icon {
      @include button-icon;
      color: $tt9-color-neutral-0;
      padding: 0 7px 7px 7px;
    }
  }

  &-btn-applied {
    position: relative;

    .applied {
      position: absolute;
      top: $size-1;
      right: $size-1;
      border-radius: 100%;
      width: 8px;
      height: 8px;
      background-color: $tt9-color-primary;
      border: $size-1 solid $tt9-color-neutral-50;
      transition: all 0.3s;
    }

    &:hover .applied {
      border-color: $tt9-color-primary-100;
    }

    &:active .applied {
      border-color: $tt9-color-primary-200;
    }

    &.active .applied {
      opacity: 0;
    }
  }
}
