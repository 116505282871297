@import "variables";

@mixin tools-panel {
  &-tools-panel {
    display: inline-flex;
    padding: 12px;
    box-shadow: 0px 4px 8px 0px $tt9-color-box-shadow-primary;
    border-radius: 8px;
    background: $tt9-color-neutral-0;
    overflow: hidden;
    gap: 12px;

    &-divider {
      margin: 0;
      border: 0;
      border-right: 1px solid $tt9-color-neutral-300;
    }
  }
}
