@mixin tabs {
  &-tabs {
    position: relative;
    margin-bottom: 24px;
    padding: 0 4px;
    overflow: auto;
    display: flex;
    box-sizing: border-box;

    &:after {
      content: "";
      position: absolute;
      bottom: 1px;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $color-neutral-600;
      z-index: 1;
    }

    &-tab {
      margin: 2px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      white-space: nowrap;
      color: $color-neutral;
      @extend .body-small;

      &:hover {
        color: $color-secondary;
      }

      mat-icon {
        color: $color-neutral;

        &:not(.add-icon) {
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          color: $color-secondary;
        }
      }

      &.active {
        position: relative;
        color: $color-neutral-900;

        &:before {
          content: "";
          position: absolute;
          bottom: -2px;
          height: 3px;
          left: 0;
          right: 0;
          background-color: $color-secondary;
          z-index: 2;
        }

        &.has-close-icon {
          &:before {
            right: 24px;
          }
        }
      }
    }
  }
}
