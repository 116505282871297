@import "mixins/form-field";
@import "mixins/form-field-select";

@mixin form-fields {
  @include form-field-select;

  $base-line-height: 24px;
  $large-line-height: 36px;

  &-form-field {
    position: relative;

    &.mat-form-field-appearance-outline {
      $default-padding: 8px;
      $large-padding: 3px;

      &:not(.no-label):not(.large) {
        @include form-field($base-line-height);
        @include form-field-template($default-padding, $base-line-height);
      }

      &.custom-width.custom-width {
        @include form-field-template($default-padding, $base-line-height, false, 0);
      }

      &.no-label:not(.large) {
        &:not(.custom-width) {
          @include form-field($base-line-height, true);
          @include form-field-template($default-padding, $base-line-height, true);
        }

        &.custom-width {
          @include form-field($base-line-height, true);
          @include form-field-template($default-padding, $base-line-height, true, 0);
        }
      }

      &.no-label.other-option-input {
        @include form-field($base-line-height, true);
        @include form-field-template($default-padding, $base-line-height, true, auto);
      }

      &.large:not(.no-label) {
        @include form-field($large-line-height);
        @include form-field-template($large-padding, $large-line-height);
      }

      &.large.no-label {
        @include form-field($large-line-height, true);
        @include form-field-template($large-padding, $large-line-height, true);
      }

      &.large .mat-mdc-input-element {
        @extend .h2;
      }

      &.middle .mat-mdc-input-element {
        @extend .h3;
      }

      &.hint-bottom .mat-mdc-form-field-subscript-wrapper {
        top: initial !important;
        bottom: 0;
      }

      &.textarea {
        textarea {
          min-height: 80px !important;
          resize: none;
        }

        .mat-form-field-label-wrapper {
          height: 48px; //height of input for position of label
        }

        .mat-form-field-label-wrapper {
          height: 48px; //height of input for position of label
        }
      }

      &.full-height-textarea {
        height: 100%;

        textarea {
          height: 100% !important;
          resize: none;
        }

        .mat-mdc-text-field-wrapper {
          display: flex;
          flex-direction: column;
          height: 100%;
          margin: 0 !important;
          min-width: auto !important;
        }

        .mat-mdc-form-field-flex {
          height: 100%;
        }

        .mat-mdc-form-field-infix {
          height: 100%;
          box-sizing: border-box;
        }

        .mat-mdc-form-field-subscript-wrapper {
          overflow: visible !important;
        }

        .mat-form-field-label-wrapper {
          height: 100%;
        }
      }

      // fix for early appeared scroll in cdkTextareaAutosize
      &.textarea-auto-size {
        box-sizing: content-box;
      }

      &.textarea-small {
        &.no-label:not(.large):not(.custom-width) .mat-mdc-form-field-infix {
          padding: 6px 0 6px !important;
        }

        &.no-label:not(.large):not(.custom-width) .mat-mdc-input-element {
          min-height: 24px !important;
        }
      }
    }

    &.with-custom-prefix .mat-mdc-form-field-infix {
      display: flex;
      flex-direction: row-reverse;
      gap: $size-1;
    }

    .mat-mdc-form-field-icon-prefix .mat-datepicker-toggle .mat-mdc-button-base,
    .mat-mdc-form-field-icon-suffix .mat-datepicker-toggle .mat-mdc-button-base {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px !important;
      height: 24px !important;
      padding: 0 !important;
    }

    .mdc-text-field__input {
      caret-color: $tt9-color-neutral !important;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      top: 2px;
    }
  }
}
