@mixin navigation-card-icon($size: 24px, $margin: 12px, $font-size: 16px) {
  display: none;
  position: absolute;
  border-radius: 50%;
  background-color: $tt9-color-neutral-0;
  align-items: center;
  justify-content: center;

  height: $size;
  width: $size;
  top: $margin;
  right: $margin;

  accenture-icon {
    color: $tt9-color-neutral-700;
  }
}

@mixin navigation-card-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $tt9-color-neutral-50;
  overflow: hidden;
  border-radius: $size-1;
  margin-bottom: $size-2;
  position: relative;

  &-empty {
    width: $medium-image-width;
    height: $medium-image-height;
  }

  &-top-badges,
  &-bottom-badges {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    left: $size-2;
    right: $size-2;
    gap: $size-2;
  }

  &-top-badges {
    top: $size-2;
    flex-direction: row-reverse;
  }

  &-bottom-badges {
    bottom: $size-2;
  }

  &-filtered {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;

    &.inactive {
      filter: grayscale(1);
      -ms-filter: gray(1);
      -webkit-filter: grayscale(1);
      -webkit-backdrop-filter: grayscale(1);
      opacity: 0.25;
    }
  }
}

@mixin navigation-card {
  &-navigation-card {
    display: flex;
    flex-direction: column;
    height: 236px;
    border-radius: $border-radius;
    border: 1px solid $tt9-color-neutral-200;
    width: 324px;
    padding: $size-2;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    box-sizing: border-box;

    &:focus-within {
      border: 1px solid $tt9-color-primary;
    }

    &.hover,
    &:hover {
      border: 1px solid $tt9-color-primary;

      .navigation-card-delete,
      .navigation-card-menu-btn {
        display: flex;
        z-index: 1;
      }
    }

    .navigation-card-delete {
      @include navigation-card-icon;

      &:hover {
        accenture-icon {
          color: $tt9-color-error-500;
        }
      }
    }

    .navigation-card-menu-btn {
      transition: all 0.3s;
      display: none;
      text-align: center;
      white-space: nowrap;
      padding: 0;
      background-color: $tt9-color-neutral-0;
      border-radius: 100px;
      border: 0;
      color: $tt9-color-neutral-500;
      cursor: pointer;

      &:hover {
        color: $tt9-color-primary;
      }
    }

    &-title {
      @extend .tt9-body-medium-1;
      @extend .text-overflow-ellipsis-2;

      font-weight: 500 !important;
      margin-bottom: $size-1;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }

    &-content {
      .client {
        @extend .text-overflow-ellipsis-1;
        @extend .tt9-body-medium-2;

        color: $tt9-color-primary;
        min-height: 18px;
      }
    }

    &-description {
      @extend .tt9-caption-1;
      @extend .text-overflow-ellipsis-1;

      color: $tt9-color-neutral-500;
      margin-bottom: $size-1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }

    &-image {
      @include navigation-card-image;
      height: 132px;
      flex-shrink: 0;
    }

    &-content {
      @extend .tt9-assistive;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .phase {
        max-width: calc((100% - 80px - 14px) / 2); // (100% -  date width - splitter) / count of phase

        &.full-width {
          max-width: calc(100% - 80px - 14px);
        }
      }

      .date {
        color: $tt9-color-neutral-500;
        margin-left: auto;
        font-weight: 500 !important;
        text-transform: uppercase;
      }
    }

    &-footer {
      display: flex;
      width: 100%;
      align-items: flex-end;

      .date {
        flex-shrink: 0;
      }

      .favorite-btn {
        &.selected {
          color: $tt9-color-warning-300;
        }
      }
    }

    &-template {
      display: flex;
      flex-direction: column;

      border-radius: $border-radius;
      border: 1px solid $tt9-color-neutral-200;
      min-width: 240px;
      max-width: 320px;
      width: 100%;

      padding: $size-2;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      box-sizing: border-box;
      min-height: 268px;
      height: 100%;

      &-image {
        @include navigation-card-image;
        height: 164px;
        flex-shrink: 0;
      }

      &:hover,
      &.hover {
        border: 1px solid $tt9-color-primary;

        .tt9-navigation-card-template-image {
          filter: brightness(50%);
        }
      }

      &-title {
        @extend .text-overflow-ellipsis-2;
        @extend .tt9-body-medium-2;
        margin-top: $size-1;
      }

      .action-buttons {
        display: none;
        flex-direction: column;
        position: absolute;

        top: $size-2;
        height: 164px;
        left: $size-2;
        right: $size-2;
        box-sizing: border-box;
        justify-content: space-between;
        padding: $size-2;

        .menu-btn {
          width: 40px;
          align-self: flex-end;
        }

        &.hover {
          display: flex;
        }
      }

      .use-template-btn {
        justify-content: center;
        width: 100%;
      }
    }

    &-wrapper {
      min-height: 140px;
      max-height: 140px;
      max-width: 400px;
      border-radius: $size-1;
      box-shadow: 0 0 0 8px $tt9-color-neutral-50;
      position: relative;
      transition: all 0.3s ease;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 0 8px $tt9-color-primary-100;
      }
    }

    &-container {
      height: 100%;
      box-shadow: 0 0 0 0 white;
      border-radius: $border-radius-small;
      padding: 12px;
    }

    &-title-with-icon {
      @extend .tt9-body-1;

      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $tt9-color-primary;

      mat-icon {
        margin-right: $size-2;
      }
    }

    &-long-description {
      @extend .tt9-body-1;
      @extend .text-overflow-ellipsis-4;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
  }
}
