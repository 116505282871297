@mixin toggle {
  &-toggle {
    &.mat-mdc-slide-toggle {
      line-height: 24px;

      .mdc-switch {
        --mdc-switch-handle-width: 10px;
        --mdc-switch-track-width: 36px;
        --mdc-switch-track-height: 16px;
        --mdc-switch-track-shape: 8px;
        --mdc-switch-disabled-track-opacity: 1;

        &.mdc-switch--selected {
          .mdc-switch__track {
            &::after {
              background: $tt9-color-primary !important;
            }
          }
        }

        &.mdc-switch--unselected {
          .mdc-switch__track {
            &::before {
              background: $tt9-color-neutral-300 !important;
            }
          }
        }

        &.mdc-switch--disabled {
          .mdc-switch__track {
            &::after,
            &::before {
              background: $tt9-color-neutral-100 !important;
            }
          }
        }

        &__handle {
          width: 8px;
          height: 8px;

          &::after,
          &::before {
            background: $tt9-color-neutral-0 !important;
          }

          &-track {
            left: $size-1;
            width: calc(100% - 16px);
          }
        }

        &__icons,
        &__shadow,
        &__ripple {
          display: none;
        }
      }

      .mdc-label {
        @include font-style(16px, 0px, 20px, 400);
        font-family: $tt9-font-primary;
        color: $tt9-color-neutral;
        padding-left: $size-2;
      }
    }
  }
}
