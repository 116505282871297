@mixin navigation-card-icon($size: 24px, $margin: 8px, $font-size: 16px) {
  display: none;
  position: absolute;
  border-radius: 50%;
  background-color: $color-neutral-100;

  height: $size;
  width: $size;
  top: $margin;
  right: $margin;

  mat-icon {
    width: $size;
    height: $size;
    font-size: $font-size;
    color: $color-neutral-700;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@mixin navigation-card {
  &-navigation-card {
    display: flex;
    flex-direction: column;
    min-height: 160px;
    position: relative;
    background-color: $color-neutral-100;
    border: 1px solid $color-neutral-600;
    border-radius: $border-radius;
    width: 288px;
    cursor: pointer;

    &:hover {
      border-color: $color-primary;

      .navigation-card-delete {
        display: flex;
      }
    }

    &-image {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 120px;
      background-color: $color-neutral-300;
      overflow: hidden;
      border-radius: $border-radius $border-radius 0 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    &.selected {
      border-color: $color-primary;

      .navigation-card-done {
        background-color: $color-primary;
        display: flex;

        mat-icon {
          color: $color-neutral-100;
        }
      }
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
    }

    .navigation-card-done {
      @include navigation-card-icon(16px, 12px, 14px);
    }

    .navigation-card-checkbox {
      display: flex;
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .navigation-card-delete {
      @include navigation-card-icon;
    }

    .navigation-card-delete {
      &:hover {
        mat-icon {
          color: $color-error-500;
        }
      }
    }

    &-image + .navigation-card-delete {
      background-color: $color-neutral-100;
    }

    &-content {
      height: 128px;
    }

    &-collections {
      @extend .body;

      padding: 20px 24px 8px;

      .accenture-category-chip {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & + p {
        padding-top: 0;
      }

      .accenture-status-filled {
        padding-left: 4px;
      }
    }

    &-type {
      padding: 16px 24px 16px 18px;
      color: $color-secondary-500;
    }

    &-title {
      @extend .body;
      font-weight: 500 !important;
      padding: 24px 24px 4px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    }

    &-title-with-icon {
      @extend .assistive;
      padding: 24px 24px 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $color-secondary;

      mat-icon {
        @extend .icon-small;
        width: 16px;
        margin-right: 4px;
      }
    }

    &-title-with-icon + &-title {
      padding-top: 0;
    }

    &-description {
      @extend .body-small;
      color: $color-neutral-800;
      padding: 0 24px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    }

    &-footer {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 24px 16px;

      & > *:first-child {
        margin-right: 8px;
      }

      &-info {
        @extend .assistive;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &-like {
        margin-left: 20px;
        padding-top: 6px;
        display: flex;
      }

      &-like-count {
        margin-left: 5px;
        align-self: center;
      }
    }
  }
}
