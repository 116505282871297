@import "variables";
@import "typography";

@mixin word-cloud {
  &-word-cloud {
    &-tooltip {
      @extend .tt9-caption-1;
      color: $tt9-color-neutral-0;
      background-color: $tt9-color-neutral-900;
      position: absolute;
      padding: $size-2;
      border-radius: $size-1;

      &::after {
        width: 0;
        height: 0;
        content: "";
        position: absolute;
        border-left: $size-2 solid transparent;
        border-right: $size-2 solid transparent;
        border-bottom: $size-2 solid $tt9-color-neutral-900;
        transform: rotate(180deg);
        bottom: -$size-2;
        right: calc(50% - $size-2);
      }
    }
  }
}
