.spacer {
  flex-grow: 1;
}

.d-inline-flex {
  display: inline-flex;
}

.d-flex {
  display: flex;
  align-items: center;

  &-start {
    display: flex;
    align-items: flex-start;
  }

  &-end {
    display: flex;
    justify-content: flex-end;
  }

  &-between {
    display: flex;
    justify-content: space-between;
  }

  &-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-start-center {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-end-between {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &-center-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &-center-evenly {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  &-center-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-start-end {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  &-column {
    display: flex;
    flex-direction: column;
  }

  &-column-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-column-start-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.flex {
  &-grow {
    &-1 {
      flex-grow: 1;
    }
  }

  &-shrink {
    &-0 {
      flex-shrink: 0;
    }
  }

  &-gap {
    @each $value in 4, 8, 16, 24 {
      &-#{$value} {
        gap: #{$value}px;
      }
    }
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-nowrap {
    flex-wrap: nowrap;
  }
}

.overflow {
  @each $value in "scroll", "hidden", "auto" {
    &-#{$value} {
      overflow: #{$value};
    }

    &-y-#{$value} {
      overflow-y: #{$value};
    }

    &-x-#{$value} {
      overflow-x: #{$value};
    }
  }
}

.visually-hidden {
  visibility: hidden;
  position: absolute;
  z-index: -100;
}

.width {
  &-100 {
    width: 100%;
  }

  &-fit-content {
    width: fit-content;
  }
}

.height-100 {
  height: 100%;
}

.m-auto {
  margin: 0 auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.default-list {
  list-style-type: disc;
  padding-left: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-col-resize {
  cursor: col-resize;
}

.events-none {
  pointer-events: none;
}

.overflow-hidden {
  overflow: hidden;
}

@each $value in 0, 4, 8, 12, 16, 20, 24, 32, 36, 40, 60 {
  .m-#{$value} {
    margin: #{$value}px;
  }

  .mt-#{$value} {
    margin-top: #{$value}px;
  }

  .mb-#{$value} {
    margin-bottom: #{$value}px;
  }

  .mr-#{$value} {
    margin-right: #{$value}px;
  }

  .ml-#{$value} {
    margin-left: #{$value}px;
  }

  .mx-#{$value} {
    margin-left: #{$value}px;
    margin-right: #{$value}px;
  }

  .my-#{$value} {
    margin-top: #{$value}px;
    margin-bottom: #{$value}px;
  }

  .p-#{$value} {
    padding: #{$value}px;
  }

  .pt-#{$value} {
    padding-top: #{$value}px;
  }

  .pb-#{$value} {
    padding-bottom: #{$value}px;
  }

  .pr-#{$value} {
    padding-right: #{$value}px;
  }

  .pl-#{$value} {
    padding-left: #{$value}px;
  }

  .px-#{$value} {
    padding-left: #{$value}px;
    padding-right: #{$value}px;
  }

  .py-#{$value} {
    padding-top: #{$value}px;
    padding-bottom: #{$value}px;
  }
}
