@import "form-field-input";

@mixin icons-color($color) {
  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-prefix mat-icon,
  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-suffix mat-icon,
  .mat-datepicker-toggle,
  .mat-datepicker-toggle .mat-mdc-icon-button,
  .mat-mdc-form-field-required-marker,
  .mat-select.mat-select-invalid .mat-mdc-select-arrow,
  .mat-mdc-select-arrow,
  .mat-mdc-form-field-icon-suffix .tooltip-info-icon {
    color: $color !important;
  }
}

@mixin labels-color($color) {
  .mat-mdc-form-field-error,
  .mat-mdc-form-field-hint {
    color: $color !important;
  }

  .mat-hint-default {
    color: $tt9-color-neutral-700 !important;
  }

  .mat-hint-light {
    color: $tt9-color-secondary-100 !important;
  }

  .mat-hint-warning {
    color: $tt9-color-warning !important;
  }

  .mat-hint-error {
    color: $tt9-color-error !important;
  }

  mat-label {
    color: $color !important;
    letter-spacing: 0.15px;
  }
}

@mixin form-field-outline {
  .mat-form-field-label {
    text-overflow: clip;
  }

  .mat-mdc-form-field-required-marker {
    font-family: $tt9-font-primary;
  }

  mat-label {
    font-family: $tt9-font-primary;
    line-height: 24px;
  }

  &:not(.mat-focused):not(.mat-form-field-invalid):not(.mat-form-field-disabled) {
    .mat-mdc-form-field-flex {
      &:hover {
        .mdc-notched-outline {
          &__leading,
          &__notch,
          &__trailing {
            border-color: $tt9-color-primary !important;
            border-width: 1px !important;
          }
        }
      }
    }
  }

  .mdc-notched-outline__leading {
    background: $tt9-color-neutral-0;
    border-color: $tt9-color-neutral-200 !important;
    border-radius: $border-radius 0 0 $border-radius !important;
    min-width: 12px;
  }

  .mdc-notched-outline__notch {
    border-color: $tt9-color-neutral-200 !important;
    border-left: none !important;
    background-color: $tt9-color-neutral-0 !important;
  }

  .mdc-notched-outline__trailing {
    background: $tt9-color-neutral-0;
    border-color: $tt9-color-neutral-200 !important;
    border-radius: 0 $border-radius $border-radius 0 !important;
  }

  &.transparent {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      background: transparent !important;
    }
  }

  &.outline-less:not(.mat-form-field-type-mat-select) {
    .mdc-notched-outline {
      &__leading,
      &__notch,
      &__trailing {
        border: none;
      }
    }
  }

  &.invisible {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: transparent !important;
    }

    .mdc-notched-outline__leading {
      background: transparent;
    }

    .mdc-notched-outline__notch {
      border-color: transparent !important;
    }

    .mdc-notched-outline__trailing {
      background: transparent;
    }

    .mat-mdc-input-element {
      text-overflow: ellipsis;
    }

    &.mat-form-field-disabled {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: transparent !important;
        background: transparent !important;
      }

      .mdc-notched-outline__notch {
        border-color: transparent !important;
      }

      .mat-mdc-input-element {
        text-overflow: ellipsis;
      }

      mat-icon.hover-visible {
        display: none !important;
      }
    }
  }

  &:not(.mat-form-field-type-mat-select) {
    .mdc-notched-outline {
      &__leading,
      &__notch,
      &__trailing {
        border-width: 1px !important;
      }
    }

    .mdc-notched-outline:not(.mdc-notched-outline--notched) {
      .mdc-notched-outline__notch {
        display: flex;
      }
    }
  }

  &.mat-form-field-type-mat-select {
    .mdc-notched-outline {
      &__leading,
      &__notch,
      &__trailing {
        border-color: $tt9-color-primary !important;
        border-width: 1px !important;
      }
    }
  }

  .mat-mdc-form-field-icon-prefix .mat-icon,
  .mat-mdc-form-field-icon-suffix .mat-icon {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  @include form-field-input;

  @include icons-color($tt9-color-neutral-500);
  @include labels-color($tt9-color-neutral-500);

  .mat-mdc-form-field-icon-suffix mat-icon.hover-visible {
    display: none !important;
  }

  mat-icon[color="primary"].suffix-active {
    color: $tt9-color-primary !important;
  }

  mat-icon[color="secondary"].suffix-active {
    color: $tt9-color-secondary !important;
  }

  &:not(.mat-form-field-disabled) {
    &.mat-focused,
    .mat-mdc-form-field-flex:hover {
      .mat-mdc-form-field-icon-suffix mat-icon.hover-visible {
        display: flex !important;
      }
    }
  }

  &.mat-focused {
    .mdc-notched-outline {
      &__leading,
      &__notch,
      &__trailing {
        border-color: $tt9-color-primary !important;
        border-width: 2px !important;
      }
    }

    .mat-mdc-form-field-icon-prefix mat-icon[color="primary"],
    .mat-mdc-form-field-icon-suffix mat-icon[color="primary"] {
      color: $tt9-color-primary !important;
    }

    .mat-mdc-form-field-icon-prefix mat-icon[color="secondary"],
    .mat-mdc-form-field-icon-suffix mat-icon[color="secondary"] {
      color: $tt9-color-secondary !important;
    }

    mat-label,
    .mat-form-field-label,
    .mat-mdc-form-field-required-marker {
      color: $tt9-color-primary !important;
    }
  }

  &.mat-form-field-invalid.mat-form-field-invalid {
    &:not(.no-margin).no-error-margin {
      .mat-mdc-text-field-wrapper {
        margin-bottom: $size-2 !important;
        padding-bottom: 0 !important;
      }
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-width: 2px !important;
      border-color: $tt9-color-error !important;
    }

    .mdc-floating-label {
      border-color: $tt9-color-error !important;

      mat-label,
      .mat-form-field-label,
      .mat-mdc-form-field-required-marker {
        color: $tt9-color-error !important;
      }
    }

    @include labels-color($tt9-color-error);
  }

  &.mat-form-field-disabled {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      background: $tt9-color-neutral-50 !important;
      border-color: $tt9-color-neutral-100 !important;
      border-width: 1px !important;
    }

    .mat-mdc-form-field-infix {
      .mat-mdc-select-value {
        color: $tt9-color-neutral-200;
      }

      .mat-mdc-select-placeholder {
        color: $tt9-color-neutral-200;
        letter-spacing: 0;
      }

      .mat-mdc-select-arrow {
        color: $tt9-color-neutral-200 !important;
      }
    }

    &.read-mode {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        background: $tt9-color-neutral-100 !important;
        border-color: $tt9-color-neutral-200 !important;
      }
    }

    @include labels-color($tt9-color-neutral-400);
    @include icons-color($tt9-color-neutral-400);
  }

  // label gap for disabled state
  &.mat-form-field-can-float.mat-form-field-should-float .mdc-notched-outline__notch {
    border-top-color: transparent !important;
  }
}
