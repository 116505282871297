@mixin primary-tooltip {
  border-radius: 8px;
  padding: 16px;
  border-radius: 8px;
  opacity: 1 !important;
  background-color: $color-neutral-100 !important;
  box-shadow: 0 8px 8px 0 $color-box-shadow-primary;
  word-break: break-word;
  @extend .body-small;

  .mdc-tooltip__surface {
    background-color: inherit !important;
    min-width: $size-3 !important;
    max-width: 250px !important;
    min-height: unset !important;
    max-height: unset !important;
    padding: 0;
    font-family: $tt9-font-primary !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: $color-neutral-900 !important;
    will-change: auto;
  }
}

@mixin tooltip {
  &-tooltip {
    @include primary-tooltip;

    &-animation {
      animation: 0.5s tooltip;

      @keyframes tooltip {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }
  }
}

@mixin tooltip-large {
  &-tooltip-large {
    @include primary-tooltip;
    max-width: 780px !important;
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
}
