.hidden-x-large-desktop {
  @include screen-x-large-desktop() {
    display: none !important;
  }
}

.hidden-large-desktop {
  @include screen-large-desktop() {
    display: none !important;
  }
}

.hidden-medium-desktop {
  @include screen-medium-desktop() {
    display: none !important;
  }
}

.hidden-small-desktop {
  @include screen-small-desktop() {
    display: none !important;
  }
}

.hidden-tablet {
  @include screen-tablet() {
    display: none !important;
  }
}

.hidden-handset {
  @include screen-handset() {
    display: none !important;
  }
}
.hidden-small-handset {
  @include screen-small-handset() {
    display: none !important;
  }
}

.hidden-all-handsets {
  @include screen-all-handset() {
    display: none !important;
  }
}

.hidden-all-mobiles {
  @include screen-all-mobiles() {
    display: none !important;
  }
}

.hidden-all-desktops {
  @include screen-all-desktops() {
    display: none !important;
  }
}

.hidden-all-desktops-and-tablets {
  @extend .hidden-all-desktops;
  @extend .hidden-tablet;
}

.visible-all-desktops {
  @extend .hidden-all-mobiles;
}

.visible-all-mobiles {
  @extend .hidden-all-desktops;
}

.visible-medium-large-desktop {
  @extend .hidden-small-desktop;
  @extend .hidden-all-mobiles;
}

$responsive-classes: "x-large-desktop", "large-desktop", "medium-desktop", "small-desktop", "small-handset", "handset",
  "tablet";

@each $class in $responsive-classes {
  .visible-#{$class} {
    @each $hiddenClass in $responsive-classes {
      @if ($hiddenClass != $class) {
        @extend .hidden-#{$hiddenClass};
      }
    }
  }
}
