// Color primary shades
$tt9-color-primary: #5348ce;
$tt9-color-primary-shades: (
  "": $tt9-color-primary,
  50: #e9ebf8,
  100: #d1cdff,
  200: #b1aaff,
  300: #5348ce,
  400: #463caf,
  500: #393090,
  600: #2b2570,
  700: #251f61,
  800: #1e1951,
  900: #110d32,
);
$tt9-color-primary-50: map-get($tt9-color-primary-shades, 50);
$tt9-color-primary-100: map-get($tt9-color-primary-shades, 100);
$tt9-color-primary-200: map-get($tt9-color-primary-shades, 200);
$tt9-color-primary-300: map-get($tt9-color-primary-shades, 300);
$tt9-color-primary-400: map-get($tt9-color-primary-shades, 400);
$tt9-color-primary-500: map-get($tt9-color-primary-shades, 500);
$tt9-color-primary-600: map-get($tt9-color-primary-shades, 600);
$tt9-color-primary-700: map-get($tt9-color-primary-shades, 700);
$tt9-color-primary-800: map-get($tt9-color-primary-shades, 800);
$tt9-color-primary-900: map-get($tt9-color-primary-shades, 900);

// Color accent shades
$tt9-color-accent: #2f80ed;
$tt9-color-accent-shades: (
  "": $tt9-color-accent,
  50: #eaf2fd,
  100: #e0ecfc,
  200: #bfd8f9,
  300: #2f80ed,
  400: #2a73d5,
  500: #2666be,
  600: #2360b2,
  700: #1c4d8e,
  800: #153a6b,
  900: #102d53,
);
$tt9-color-accent-50: map-get($tt9-color-accent-shades, 50);
$tt9-color-accent-100: map-get($tt9-color-accent-shades, 100);
$tt9-color-accent-200: map-get($tt9-color-accent-shades, 200);
$tt9-color-accent-300: map-get($tt9-color-accent-shades, 300);
$tt9-color-accent-400: map-get($tt9-color-accent-shades, 400);
$tt9-color-accent-500: map-get($tt9-color-accent-shades, 500);
$tt9-color-accent-600: map-get($tt9-color-accent-shades, 600);
$tt9-color-accent-700: map-get($tt9-color-accent-shades, 700);
$tt9-color-accent-800: map-get($tt9-color-accent-shades, 800);
$tt9-color-accent-900: map-get($tt9-color-accent-shades, 900);

// Color primary second shades
$tt9-color-secondary: #a055f5;
$tt9-color-secondary-shades: (
  "": $tt9-color-secondary,
  50: #fcf9ff,
  100: #f3e9fe,
  200: #e0c8fc,
  300: #a055f5,
  400: #8f46e2,
  500: #7e37ce,
  600: #6c27bb,
  700: #6420b1,
  800: #53119e,
  900: #4a0994,
);
$tt9-color-secondary-50: map-get($tt9-color-secondary-shades, 50);
$tt9-color-secondary-100: map-get($tt9-color-secondary-shades, 100);
$tt9-color-secondary-200: map-get($tt9-color-secondary-shades, 200);
$tt9-color-secondary-300: map-get($tt9-color-secondary-shades, 300);
$tt9-color-secondary-400: map-get($tt9-color-secondary-shades, 400);
$tt9-color-secondary-500: map-get($tt9-color-secondary-shades, 500);
$tt9-color-secondary-600: map-get($tt9-color-secondary-shades, 600);
$tt9-color-secondary-700: map-get($tt9-color-secondary-shades, 700);
$tt9-color-secondary-800: map-get($tt9-color-secondary-shades, 800);
$tt9-color-secondary-900: map-get($tt9-color-secondary-shades, 900);

// Color primary neutral shades
$tt9-color-neutral: #151618;
$tt9-color-neutral-shades: (
  "": $tt9-color-neutral,
  0: #ffffff,
  50: #f2f2f2,
  75: #e8e8e8,
  100: #dbdbdb,
  200: #cacaca,
  300: #b2b2b2,
  400: #a3a3a3,
  500: #8c8c8c,
  600: #7f7f7f,
  700: #636363,
  800: #4d4d4d,
  900: #3b3b3b,
);
$tt9-color-neutral-0: map-get($tt9-color-neutral-shades, 0);
$tt9-color-neutral-50: map-get($tt9-color-neutral-shades, 50);
$tt9-color-neutral-75: map-get($tt9-color-neutral-shades, 75);
$tt9-color-neutral-100: map-get($tt9-color-neutral-shades, 100);
$tt9-color-neutral-200: map-get($tt9-color-neutral-shades, 200);
$tt9-color-neutral-300: map-get($tt9-color-neutral-shades, 300);
$tt9-color-neutral-400: map-get($tt9-color-neutral-shades, 400);
$tt9-color-neutral-500: map-get($tt9-color-neutral-shades, 500);
$tt9-color-neutral-600: map-get($tt9-color-neutral-shades, 600);
$tt9-color-neutral-700: map-get($tt9-color-neutral-shades, 700);
$tt9-color-neutral-800: map-get($tt9-color-neutral-shades, 800);
$tt9-color-neutral-900: map-get($tt9-color-neutral-shades, 900);

// Color primary success shades
$tt9-color-success: #05f0a5;
$tt9-color-success-shades: (
  "": $tt9-color-success,
  50: #e9f7ef,
  75: #dff2eb,
  100: #d4fef0,
  200: #ebfff8,
  300: #05f0a5,
  400: #04c084,
  500: #039063,
  600: #026042,
  700: #024832,
  800: #013021,
  900: #011811,
);
$tt9-color-success-50: map-get($tt9-color-success-shades, 50);
$tt9-color-success-75: map-get($tt9-color-success-shades, 75);
$tt9-color-success-100: map-get($tt9-color-success-shades, 100);
$tt9-color-success-200: map-get($tt9-color-success-shades, 200);
$tt9-color-success-300: map-get($tt9-color-success-shades, 300);
$tt9-color-success-400: map-get($tt9-color-success-shades, 400);
$tt9-color-success-500: map-get($tt9-color-success-shades, 500);
$tt9-color-success-600: map-get($tt9-color-success-shades, 600);
$tt9-color-success-700: map-get($tt9-color-success-shades, 700);
$tt9-color-success-800: map-get($tt9-color-success-shades, 800);
$tt9-color-success-900: map-get($tt9-color-success-shades, 900);

// Color primary warning shades
$tt9-color-warning: #ffba00;
$tt9-color-warning-shades: (
  "": $tt9-color-warning,
  50: #fff8e6,
  100: #fff5d9,
  200: #ffeab0,
  300: #ffba00,
  400: #e6a700,
  500: #cc9500,
  600: #bf8c00,
  700: #997000,
  800: #735400,
  900: #594100,
);
$tt9-color-warning-50: map-get($tt9-color-warning-shades, 50);
$tt9-color-warning-100: map-get($tt9-color-warning-shades, 100);
$tt9-color-warning-200: map-get($tt9-color-warning-shades, 200);
$tt9-color-warning-300: map-get($tt9-color-warning-shades, 300);
$tt9-color-warning-400: map-get($tt9-color-warning-shades, 400);
$tt9-color-warning-500: map-get($tt9-color-warning-shades, 500);
$tt9-color-warning-600: map-get($tt9-color-warning-shades, 600);
$tt9-color-warning-700: map-get($tt9-color-warning-shades, 700);
$tt9-color-warning-800: map-get($tt9-color-warning-shades, 800);
$tt9-color-warning-900: map-get($tt9-color-warning-shades, 900);

// Color primary danger shades
$tt9-color-error: #d61f26;
$tt9-color-error-shades: (
  "": $tt9-color-error,
  50: #fbe2e3,
  75: #eed6d7,
  100: #f9d0d2,
  200: #f6bec0,
  300: #d61f26,
  400: #c2262c,
  500: #a11f24,
  600: #7f171b,
  700: #6f1317,
  800: #5e1013,
  900: #4e0c0e,
);
$tt9-color-error-50: map-get($tt9-color-error-shades, 50);
$tt9-color-error-75: map-get($tt9-color-error-shades, 75);
$tt9-color-error-100: map-get($tt9-color-error-shades, 100);
$tt9-color-error-200: map-get($tt9-color-error-shades, 200);
$tt9-color-error-300: map-get($tt9-color-error-shades, 300);
$tt9-color-error-400: map-get($tt9-color-error-shades, 400);
$tt9-color-error-500: map-get($tt9-color-error-shades, 500);
$tt9-color-error-600: map-get($tt9-color-error-shades, 600);
$tt9-color-error-700: map-get($tt9-color-error-shades, 700);
$tt9-color-error-800: map-get($tt9-color-error-shades, 800);
$tt9-color-error-900: map-get($tt9-color-error-shades, 900);

$tt9-accent-colors: (
  0: (
    0: #f5752d,
    1: #f54f2d,
  ),
  1: (
    0: #fed235,
    1: #fe8535,
  ),
  2: (
    0: #faff00,
    1: #ffba00,
  ),
  3: (
    0: #b4ff54,
    1: #6fc42b,
  ),
  4: (
    0: #00ff6c,
    1: #27ae60,
  ),
  5: (
    0: #00fffe,
    1: #01c5c4,
  ),
  6: (
    0: #56f5ff,
    1: #40aef2,
  ),
  7: (
    0: #5fb2ff,
    1: #5348ce,
  ),
  8: (
    0: #ff6ac4,
    1: #dd75ec,
  ),
  9: (
    0: #dd75ec,
    1: #8f46e2,
  ),
  10: (
    0: #6fc32b,
    1: #6fc32b,
  ),
);

// Box shadow
$tt9-color-box-shadow-primary: rgba(0, 0, 0, 0.13);

// Select all checkbox
$tt9-checkbox-select-all: #3f51b5;

$tt9-color-info-text: #146bd0;
