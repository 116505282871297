@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?34002016');
  src: url('../font/fontello.eot?34002016#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?34002016') format('woff2'),
       url('../font/fontello.woff?34002016') format('woff'),
       url('../font/fontello.ttf?34002016') format('truetype'),
       url('../font/fontello.svg?34002016#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?34002016#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-folder-in:before { content: '\0e86'; } /* 'ຆ' */
.icon-add:before { content: '\e800'; } /* '' */
.icon-bookmark:before { content: '\e801'; } /* '' */
.icon-bookmark-outline:before { content: '\e802'; } /* '' */
.icon-chat-fill:before { content: '\e803'; } /* '' */
.icon-calendar:before { content: '\e804'; } /* '' */
.icon-chat:before { content: '\e805'; } /* '' */
.icon-close:before { content: '\e806'; } /* '' */
.icon-delete:before { content: '\e807'; } /* '' */
.icon-drop-down:before { content: '\e808'; } /* '' */
.icon-drop-right:before { content: '\e809'; } /* '' */
.icon-drop-up:before { content: '\e80a'; } /* '' */
.icon-edit:before { content: '\e80b'; } /* '' */
.icon-check-square:before { content: '\e80c'; } /* '' */
.icon-done:before { content: '\e80d'; } /* '' */
.icon-focus:before { content: '\e80e'; } /* '' */
.icon-looks-one:before { content: '\e80f'; } /* '' */
.icon-search:before { content: '\e810'; } /* '' */
.icon-settings:before { content: '\e811'; } /* '' */
.icon-star-outline:before { content: '\e812'; } /* '' */
.icon-star:before { content: '\e813'; } /* '' */
.icon-text-fields:before { content: '\e814'; } /* '' */
.icon-unfold-more:before { content: '\e815'; } /* '' */
.icon-check-bold:before { content: '\e816'; } /* '' */
.icon-visibility:before { content: '\e817'; } /* '' */
.icon-attachment:before { content: '\e818'; } /* '' */
.icon-bar-chart-horizontally:before { content: '\e819'; } /* '' */
.icon-branching:before { content: '\e81a'; } /* '' */
.icon-bar-chart:before { content: '\e81b'; } /* '' */
.icon-bubble-chart:before { content: '\e81c'; } /* '' */
.icon-connection:before { content: '\e81d'; } /* '' */
.icon-analyze:before { content: '\e81e'; } /* '' */
.icon-duplicate:before { content: '\e81f'; } /* '' */
.icon-error:before { content: '\e820'; } /* '' */
.icon-bell:before { content: '\e821'; } /* '' */
.icon-filter:before { content: '\e822'; } /* '' */
.icon-folder:before { content: '\e823'; } /* '' */
.icon-fullscreen-exit:before { content: '\e824'; } /* '' */
.icon-fullscreen:before { content: '\e825'; } /* '' */
.icon-group:before { content: '\e826'; } /* '' */
.icon-library-add:before { content: '\e827'; } /* '' */
.icon-linear-scale:before { content: '\e828'; } /* '' */
.icon-link-off:before { content: '\e829'; } /* '' */
.icon-link:before { content: '\e82a'; } /* '' */
.icon-mail:before { content: '\e82b'; } /* '' */
.icon-person-add:before { content: '\e82c'; } /* '' */
.icon-pie-chart:before { content: '\e82d'; } /* '' */
.icon-play:before { content: '\e82e'; } /* '' */
.icon-concierge-figma:before { content: '\e82f'; } /* '' */
.icon-ai:before { content: '\e830'; } /* '' */
.icon-video:before { content: '\e831'; } /* '' */
.icon-warning:before { content: '\e832'; } /* '' */
.icon-arrow-downward:before { content: '\e833'; } /* '' */
.icon-arrow-upward:before { content: '\e834'; } /* '' */
.icon-cloud:before { content: '\e835'; } /* '' */
.icon-column:before { content: '\e836'; } /* '' */
.icon-drag-horizontal:before { content: '\e837'; } /* '' */
.icon-drag-handle:before { content: '\e838'; } /* '' */
.icon-drag-vertical:before { content: '\e839'; } /* '' */
.icon-filter-alt:before { content: '\e83a'; } /* '' */
.icon-download:before { content: '\e83b'; } /* '' */
.icon-label-outline:before { content: '\e83c'; } /* '' */
.icon-label:before { content: '\e83d'; } /* '' */
.icon-list:before { content: '\e83e'; } /* '' */
.icon-merge-to:before { content: '\e83f'; } /* '' */
.icon-step-back:before { content: '\e840'; } /* '' */
.icon-north-east:before { content: '\e841'; } /* '' */
.icon-open-in-full:before { content: '\e842'; } /* '' */
.icon-percent:before { content: '\e843'; } /* '' */
.icon-responses:before { content: '\e844'; } /* '' */
.icon-send:before { content: '\e845'; } /* '' */
.icon-sort:before { content: '\e846'; } /* '' */
.icon-south-east:before { content: '\e847'; } /* '' */
.icon-ungroup:before { content: '\e848'; } /* '' */
.icon-swap-calls:before { content: '\e849'; } /* '' */
.icon-response-up-2:before { content: '\e84a'; } /* '' */
.icon-double-check:before { content: '\e84b'; } /* '' */
.icon-chevron-down-small:before { content: '\e84c'; } /* '' */
.icon-chevron-left-small:before { content: '\e84d'; } /* '' */
.icon-chevron-down:before { content: '\e84e'; } /* '' */
.icon-chevron-left:before { content: '\e84f'; } /* '' */
.icon-chevron-right-small:before { content: '\e850'; } /* '' */
.icon-chevron-right:before { content: '\e851'; } /* '' */
.icon-chevron-up-small:before { content: '\e852'; } /* '' */
.icon-chevron-up:before { content: '\e853'; } /* '' */
.icon-more:before { content: '\e854'; } /* '' */
.icon-response-up-1:before { content: '\e855'; } /* '' */
.icon-file-check:before { content: '\e856'; } /* '' */
.icon-file-plus:before { content: '\e857'; } /* '' */
.icon-file:before { content: '\e858'; } /* '' */
.icon-image:before { content: '\e859'; } /* '' */
.icon-facilitate-notes:before { content: '\e85a'; } /* '' */
.icon-ai-summarize:before { content: '\e85b'; } /* '' */
.icon-corner-left-up:before { content: '\e85c'; } /* '' */
.icon-summarize:before { content: '\e85d'; } /* '' */
.icon-anonymous:before { content: '\e85e'; } /* '' */
.icon-visibility-off:before { content: '\e85f'; } /* '' */
.icon-favorite-outline:before { content: '\e860'; } /* '' */
.icon-favorite:before { content: '\e861'; } /* '' */
.icon-check:before { content: '\e862'; } /* '' */
.icon-check-word:before { content: '\e863'; } /* '' */
.icon-side-window:before { content: '\e864'; } /* '' */
.icon-expand-1:before { content: '\e865'; } /* '' */
.icon-zoom-in-1:before { content: '\e866'; } /* '' */
.icon-emoji-active:before { content: '\e867'; } /* '' */
.icon-emoji-default:before { content: '\e868'; } /* '' */
.icon-folder-fill:before { content: '\e869'; } /* '' */
.icon-folder-x:before { content: '\e86a'; } /* '' */
.icon-folder-out-1:before { content: '\e86b'; } /* '' */
.icon-pin-response:before { content: '\e86c'; } /* '' */
.icon-response-up:before { content: '\e86d'; } /* '' */
.icon-folder-out:before { content: '\e86e'; } /* '' */
.icon-list-compact:before { content: '\e86f'; } /* '' */
.icon-sparkle:before { content: '\e870'; } /* '' */
.icon-more-horizontal:before { content: '\e871'; } /* '' */
.icon-dash:before { content: '\e872'; } /* '' */
.icon-row-up:before { content: '\e879'; } /* '' */
.icon-row-down:before { content: '\e87a'; } /* '' */
.icon-information:before { content: '\e87c'; } /* '' */
.icon-scale:before { content: '\e87d'; } /* '' */
.icon-help:before { content: '\e87e'; } /* '' */
.icon-customer-hub:before { content: '\e880'; } /* '' */
.icon-qna:before { content: '\e881'; } /* '' */
.icon-number-list:before { content: '\e882'; } /* '' */
.icon-breakout-room:before { content: '\e883'; } /* '' */
.icon-share:before { content: '\e885'; } /* '' */
.icon-leave:before { content: '\e886'; } /* '' */
.icon-live:before { content: '\e887'; } /* '' */
.icon-sync:before { content: '\e888'; } /* '' */
.icon-open-outside:before { content: '\e88a'; } /* '' */
.icon-meeting-time:before { content: '\e88b'; } /* '' */
.icon-call-recording:before { content: '\e88c'; } /* '' */
.icon-presenter:before { content: '\e88d'; } /* '' */
.icon-no-edit:before { content: '\e894'; } /* '' */
.icon-book-open:before { content: '\e895'; } /* '' */
.icon-people-group:before { content: '\e896'; } /* '' */
.icon-info:before { content: '\e897'; } /* '' */
.icon-grid-module:before { content: '\e898'; } /* '' */
.icon-hierarchy-module:before { content: '\e899'; } /* '' */
.icon-clock:before { content: '\e89b'; } /* '' */
.icon-clipboard:before { content: '\e89c'; } /* '' */
.icon-notes:before { content: '\e89d'; } /* '' */
.icon-log-in:before { content: '\e89f'; } /* '' */
.icon-log-out:before { content: '\e8a0'; } /* '' */
.icon-user-check:before { content: '\e8a1'; } /* '' */
.icon-send-outline:before { content: '\e8a2'; } /* '' */
.icon-user:before { content: '\e8a3'; } /* '' */
.icon-accenture-sign-on:before { content: '\e8a4'; } /* '' */
.icon-cpu:before { content: '\e8a5'; } /* '' */
.icon-filter-response:before { content: '\e8a6'; } /* '' */
.icon-channel-share:before { content: '\e8a7'; } /* '' */
.icon-light-bulb:before { content: '\e8a8'; } /* '' */
.icon-sidebar:before { content: '\e8a9'; } /* '' */
.icon-present:before { content: '\e8aa'; } /* '' */
.icon-collection:before { content: '\e8ab'; } /* '' */
.icon-power:before { content: '\e8ac'; } /* '' */
.icon-duplicate-with-responses:before { content: '\e8ad'; } /* '' */
.icon-excel:before { content: '\e8ae'; } /* '' */
.icon-move:before { content: '\e8b0'; } /* '' */
.icon-delete-all:before { content: '\e8b1'; } /* '' */
.icon-terms-of-use:before { content: '\e8b5'; } /* '' */
.icon-screenshot:before { content: '\e8b6'; } /* '' */
.icon-sidebar-right:before { content: '\e8b7'; } /* '' */
.icon-jira:before { content: '\e8b9'; } /* '' */
.icon-change-content:before { content: '\e8ba'; } /* '' */
.icon-view:before { content: '\e8bb'; } /* '' */
.icon-filters:before { content: '\e8bc'; } /* '' */
.icon-back:before { content: '\e8bd'; } /* '' */
.icon-expand:before { content: '\e8bf'; } /* '' */
.icon-collapse:before { content: '\e8c0'; } /* '' */
.icon-collapse-1:before { content: '\e8c1'; } /* '' */
.icon-burger:before { content: '\e8c3'; } /* '' */
.icon-templates-favorites:before { content: '\e8c4'; } /* '' */
.icon-templates-store:before { content: '\e8c5'; } /* '' */
.icon-brainstorms-activity:before { content: '\e8c6'; } /* '' */
.icon-zoom-out-1:before { content: '\e8c7'; } /* '' */
.icon-form-activity:before { content: '\e8c8'; } /* '' */
.icon-present-activity:before { content: '\e8c9'; } /* '' */
.icon-quick-poll:before { content: '\e8ca'; } /* '' */
.icon-section:before { content: '\e8cb'; } /* '' */
.icon-table-view:before { content: '\e8cc'; } /* '' */
.icon-vote-activity:before { content: '\e8cd'; } /* '' */
.icon-add-comment:before { content: '\e8ce'; } /* '' */
.icon-award:before { content: '\e8cf'; } /* '' */
.icon-sort-up:before { content: '\e8d0'; } /* '' */
.icon-comment:before { content: '\e8d1'; } /* '' */
.icon-create-scratch:before { content: '\e8d2'; } /* '' */
.icon-create-template:before { content: '\e8d3'; } /* '' */
.icon-file-text:before { content: '\e8d4'; } /* '' */
.icon-gift:before { content: '\e8d5'; } /* '' */
.icon-home:before { content: '\e8d6'; } /* '' */
.icon-lock:before { content: '\e8d7'; } /* '' */
.icon-map-pin:before { content: '\e8d8'; } /* '' */
.icon-phone:before { content: '\e8d9'; } /* '' */
.icon-save:before { content: '\e8da'; } /* '' */
.icon-sort-down:before { content: '\e8db'; } /* '' */
.icon-template:before { content: '\e8dc'; } /* '' */
.icon-question:before { content: '\e8f9'; } /* '' */
.icon-alert-triangle:before { content: '\e907'; } /* '' */
.icon-minus-circle:before { content: '\e908'; } /* '' */
.icon-single-select:before { content: '\e92f'; } /* '' */
.icon-chat-bubble:before { content: '\e930'; } /* '' */
.icon-chat-bubble-fill:before { content: '\e931'; } /* '' */
.icon-thumb-down:before { content: '\e932'; } /* '' */
.icon-thumb-down-outline:before { content: '\e933'; } /* '' */
.icon-thumb-up:before { content: '\e934'; } /* '' */
.icon-thumb-up-outline:before { content: '\e935'; } /* '' */
.icon-file-import:before { content: '\e947'; } /* '' */
.icon-file-upload:before { content: '\e948'; } /* '' */
.icon-move-to:before { content: '\e949'; } /* '' */
