@mixin drag-left-placeholder {
  &-drag-left-placeholder {
    display: block;
    background-color: $color-secondary-100;
    border: 1px solid $color-secondary-500;
    height: 40px;
    width: 100%;
    border-radius: $border-radius;

    &-container {
      padding: 8px;
      max-width: inherit;
    }
  }

  &-drag-left-container {
    position: relative;
    max-width: inherit;

    .drag-left-handler {
      opacity: 0;
      left: -24px;
      top: calc(50% - 12px);
      z-index: 2;
      cursor: move;
      position: absolute;
    }

    &:hover {
      .drag-left-handler {
        opacity: 1;
      }
    }
  }
}
