@each $index, $colors in $tt9-accent-colors {
  &-accent-color-#{$index} {
    background-color: map-get($colors, 1);
  }
}

&-color-primary {
  color: $tt9-color-primary !important;
}

&-color-neutral {
  color: $tt9-color-neutral;
}

&-color-neutral-0 {
  color: $tt9-color-neutral-0;
}

&-color-neutral-500 {
  color: $tt9-color-neutral-500;
}

&-color-neutral-600 {
  color: $tt9-color-neutral-600;
}

&-color-secondary-500 {
  color: $tt9-color-secondary-500;
}

&-color-secondary-600 {
  color: $tt9-color-secondary-600;
}

&-color-success-500 {
  color: $tt9-color-success-500;
}

&-color-error {
  color: $tt9-color-error;
}

&-color-neutral-300 {
  color: $tt9-color-neutral-300;
}

&-color-neutral-900 {
  color: $tt9-color-neutral-900;
}

&-bg {
  &-color-primary {
    background-color: $tt9-color-primary !important;
  }

  &-color-neutral {
    background-color: $tt9-color-neutral;
  }

  &-color-error {
    background-color: $tt9-color-error;
  }
}
