@mixin table {
  &-table {
    width: 100%;
    background: $color-neutral-100;
    font-size: 14px;

    &-container {
      width: 100%;
      max-height: 100%;
      overflow: auto;
      box-sizing: border-box;
      border-top: 1px solid $color-neutral-600;

      &.horizontal-shadow-visible .horizontal-shadow:after {
        content: "";
        position: absolute;
        box-shadow: 0 8px 8px $color-box-shadow-primary;
        height: 8px;
        width: 100%;
        bottom: 0;
        left: 0;
      }

      &.vertical-shadow-visible .vertical-shadow:after {
        content: "";
        position: absolute;
        box-shadow: 8px 4px 8px $color-box-shadow-primary;
        height: 100%;
        width: 8px;
        right: 0;
        top: 0;
      }
    }

    thead {
      box-shadow: 0 1px 0 $color-neutral-600, 0 -1px 0 $color-neutral-600;
      background-color: $color-neutral-200;
      position: sticky;
      top: 0;
      z-index: 3;

      tr {
        box-shadow: inset 0 1px 0 0 $color-neutral-600, 0 -1px 0 $color-neutral-600;
      }
    }

    th {
      height: 40px !important;
      background-color: $color-neutral-300;
      color: $color-neutral-700 !important;
      border-top: 0 !important;
      border-bottom: 0 !important;
      box-sizing: border-box;
    }

    th,
    td {
      height: 24px;
      padding: 8px 16px;
      color: $color-neutral-900;
      font-size: 14px;
      border: 1px solid $color-neutral-600;
      border-width: 0 1px 1px 0;
      vertical-align: middle;
      position: relative;
      box-sizing: border-box;
    }

    .cell {
      &-sequence {
        padding: 0;
        text-align: center !important;
        width: 40px;
        min-width: 40px;
      }
    }

    .column {
      &-sticky {
        position: sticky;
        left: 1px;
        z-index: 2;
        border: none;
      }
    }

    td.column-sticky {
      background: $color-neutral-100;
      box-shadow: inset -1px -1px 0 0 $color-neutral-600, 0 -1px 0 $color-neutral-600,
        inset 1px 0 0 0 $color-neutral-600;
    }

    th.column-sticky {
      box-shadow: inset -1px 0 0 0 $color-neutral-600, 0 -1px 0 $color-neutral-600, inset 1px 0 0 0 $color-neutral-600;
    }
  }
}
