@import "form-field-outline";
@import "tt9-theme/variables";

@mixin form-field-template($input-padding: 8px, $line-height: 24px, $no-label: false, $min-width: 288px) {
  $label-height: 8px !default;
  @if $no-label {
    $label-height: 0px;
  }

  $field-height: $line-height + $input-padding + $input-padding;

  &:not(.mat-focused) .mat-hint-limit {
    display: none;
  }

  .mat-mdc-form-field-infix {
    display: flex;
    align-items: center;
    min-height: $field-height;
    padding: $input-padding 0 !important;
    z-index: 5;
  }

  .mat-mdc-form-field-subscript-wrapper {
    top: calc(100% - 8px);
  }

  &.no-margin .mat-mdc-form-field-subscript-wrapper {
    top: 100%;
  }

  &.mat-form-field-invalid.no-error-margin:not(.no-label) .mat-mdc-form-field-subscript-wrapper {
    top: calc(100% - 8px);
  }

  @include screen-all-mobiles() {
    .mat-mdc-text-field-wrapper {
      min-width: auto;
    }
  }

  @include screen-all-desktops() {
    .mat-mdc-text-field-wrapper {
      min-width: $min-width;
    }
  }
}

@mixin form-field($line-height: 24px, $no-label: false) {
  $label-height: 8px !default;

  @if $no-label {
    $label-height: 0px;
  }
  @include form-field-outline;

  .mat-mdc-text-field-wrapper {
    margin: $label-height 0 $size-2 !important;
    padding: 0 !important;
    padding-bottom: 20px;
    color: $tt9-color-neutral;
  }

  &.full-height-textarea .mat-mdc-text-field-wrapper {
    margin: 0 !important;
  }

  &.no-margin:not(.mat-form-field-invalid) {
    .mat-mdc-text-field-wrapper {
      margin-bottom: 0 !important;
    }
  }

  &.mat-mdc-form-field-has-icon-prefix {
    .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
      left: 28px;
    }
  }

  &:not(.textarea) .mat-mdc-form-field-flex {
    padding: 0 16px;
    margin-top: 0 !important;
    background: transparent;

    .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
      position: absolute;
      top: 50%;
      transform: translate(12px, -50%) !important;
      will-change: auto;
    }

    .mat-mdc-floating-label {
      &.mdc-floating-label--float-above {
        top: 27px !important;
        padding: 0 1px;
      }
    }
  }

  &.textarea .mat-mdc-form-field-flex {
    padding: 0 $size-3 !important;
    margin-top: 0 !important;
    background: transparent;

    .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      transform: translate(16px, 8px);
    }

    .mat-mdc-floating-label {
      &.mdc-floating-label--float-above {
        top: 27px !important;
        padding: 0 1px;
      }
    }
  }

  .mat-mdc-form-field-infix {
    letter-spacing: 0;
    font-family: $tt9-font-primary;
    line-height: $line-height;
    border-top-width: 0;
  }

  & .mat-mdc-form-field-icon-prefix {
    left: -4px !important;
    padding: 0 !important;
  }

  & .mat-select-arrow-wrapper {
    top: 6px !important;
  }

  & .mat-select-arrow-wrapper,
  & .mat-mdc-form-field-icon-suffix {
    right: -8px !important;
    position: relative;
    cursor: pointer;

    svg {
      font-size: 14px;
    }
  }

  &.with-default-select-arrow-alignment .mat-select-arrow-wrapper {
    top: 0 !important;
  }

  & .mat-mdc-form-field-icon-prefix .mat-icon,
  & .mat-mdc-form-field-icon-suffix .mat-icon {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
  }

  & .mat-mdc-form-field-infix {
    padding: 12px 0 $size-2;
  }

  .mat-mdc-form-field-icon-prefix .mat-icon-button,
  .mat-mdc-form-field-icon-suffix .mat-icon-button {
    height: $line-height !important;
    width: $line-height !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    position: absolute;
    padding: 0 $size-3 !important;
    font-size: 12px;
    font-family: $tt9-font-primary;
    line-height: 14px;
    letter-spacing: 0.15px;
  }

  .mat-form-field-label-wrapper {
    top: 0;
    padding-top: 0;
    overflow: visible;

    .mat-form-field-label {
      margin-top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
    }
  }

  & .mat-mdc-floating-label.mdc-floating-label--float-above,
  &.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    will-change: auto;

    mat-label {
      letter-spacing: 0.35px !important;
    }
  }

  &.has-hint,
  &.mat-form-field-invalid:not(.no-error-margin) {
    .mat-mdc-form-field-subscript-wrapper {
      position: relative;
      top: auto !important;
      height: 20px;
      margin-bottom: $size-2 !important;
    }

    .mat-mdc-text-field-wrapper {
      margin-bottom: 0px !important;
    }
  }

  &.full-height-textarea.has-hint {
    .mat-mdc-form-field-subscript-wrapper {
      margin-bottom: 0 !important;
    }
  }

  &.date-form-field {
    .mat-mdc-form-field-icon-suffix {
      display: flex;
      align-items: center;
    }
  }

  .mat-mdc-select-placeholder {
    color: $tt9-color-neutral-500 !important;
    letter-spacing: 0;
  }

  &.mat-form-field-disabled .mat-mdc-select-placeholder {
    color: $tt9-color-neutral-400 !important;
  }
}
