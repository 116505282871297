@mixin overlay-input {
  &-overlay-input {
    &-bottom {
      bottom: 0 !important;
      right: 0 !important;
      left: 0 !important;
      width: 100%;
    }
  }
}
