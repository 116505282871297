@import "variables";

@mixin breadcrumb-container {
  display: inline-block;
}

@mixin breadcrumbs {
  &-breadcrumbs {
    display: flex;
    margin: 0;
    width: 100%;
    box-sizing: border-box;

    .breadcrumb {
      display: flex;
      align-items: center;
      color: $tt9-color-neutral-0;
      font-size: 12px;
      line-height: 16px;
      max-width: 150px;
      font-weight: 400;
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include screen-medium-desktop() {
        max-width: 96px;
      }

      @include screen-custom-range($screen-size-4, $screen-size-10) {
        max-width: 80px;
      }

      &-tooltip {
        text-transform: capitalize;
      }

      &-empty {
        color: $color-secondary-500;
      }

      &-link {
        text-decoration: none;
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          text-decoration: underline;
        }
      }

      & a {
        color: $tt9-color-neutral-0;
      }

      &:last-child a {
        color: $tt9-color-neutral-500;
        cursor: default;
        text-decoration: none;
      }

      &:not(:last-child) a {
        cursor: pointer;
      }

      &:not(:first-child):before {
        font-family: "fontello";
        content: "\e851";
        color: $tt9-color-neutral-0;
        font-size: 24px;
        line-height: 19px;
        margin: 0 4px;
      }

      &:not(:first-child):not(:last-child):before {
        content: "\e851";
      }

      &:not(:first-child):last-child:before {
        content: "\e851";
      }
    }
  }
}
