@mixin form-field-select {
  &-select-panel-custom {
    font-family: $tt9-font-primary;
    border: 1px solid $tt9-color-neutral-50 !important;
    box-sizing: border-box !important;
    box-shadow: 0 8px 16px rgba(#000, 0.18) !important;
    border-radius: $border-radius-small !important;
    min-width: 100% !important;
    max-width: 100% !important;
    outline: none !important;
    padding: $size-2 !important;
    background: $tt9-color-neutral-0;

    // Disable view of panel for preventing animation issues
    &.ng-animating {
      visibility: hidden !important;
    }

    .mat-mdc-option.mat-active {
      background: transparent !important;
    }

    .mat-mdc-option.mat-selected:not(.mat-option-disabled):not(.child-option) {
      color: $tt9-color-neutral !important;
      border-radius: $border-radius;
      margin: 0 $size-2;
      padding: $size-2;
    }

    .mat-mdc-option:not(.mat-option-disabled):not(.child-option):hover {
      background: $tt9-color-neutral-50 !important;
      border-radius: $border-radius;
    }

    .mat-mdc-option .mat-icon {
      margin-right: $size-1;
    }

    .mat-mdc-option.with-custom-icon .mdc-list-item__primary-text {
      display: flex;
      align-items: center;
      gap: $size-2;
      padding: $size-2 0;
    }

    .mat-mdc-option {
      min-height: 36px !important;
      background-color: transparent !important;
      padding: 0 $size-2;

      &:not(.mat-mdc-option-multiple) .mat-pseudo-checkbox {
        display: none !important;
      }
    }

    // for checkboxes in dropdowns
    .mat-pseudo-checkbox {
      margin-right: 12px !important;
      color: $tt9-color-neutral-400;

      &.mat-pseudo-checkbox-checked {
        background: $tt9-color-primary !important;

        &:after {
          top: -1px;
          left: 1px;
          width: 10px;
          height: 3px;
        }
      }

      &.mat-pseudo-checkbox-disabled {
        color: $tt9-color-neutral-100;
      }

      &.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked {
        background-color: $tt9-color-neutral-100;
      }
    }

    // For multi line text
    &-select-panel-with-multi-line {
      .mat-mdc-option {
        height: auto !important;
        line-height: 1 !important;
        white-space: normal !important;

        &.mat-active .mat-pseudo-checkbox:not(.mat-pseudo-checkbox-disabled),
        &:hover .mat-pseudo-checkbox:not(.mat-pseudo-checkbox-disabled) {
          color: $tt9-color-primary;
        }

        &.mat-active .mat-pseudo-checkbox-checked:not(.mat-pseudo-checkbox-disabled),
        &:hover .mat-pseudo-checkbox-checked:not(.mat-pseudo-checkbox-disabled) {
          background-color: $tt9-color-primary;
        }

        &.mat-active:not(.mat-option-disabled),
        &:focus:not(.mat-option-disabled),
        &:hover:not(.mat-option-disabled) {
          background: $tt9-color-neutral-50;
        }

        &.mat-active.mat-option-disabled {
          background: none;
        }
      }
    }

    .mdc-list-item__primary-text {
      @include font-style(16px, 0, 24px, 400);
      font-family: $tt9-font-primary !important;
      line-height: 1 !important;
      min-width: calc(100% - 32px);
      max-width: 100px;
      padding: 12px 0;
      color: $tt9-color-neutral !important;
    }

    &.mat-mdc-select-panel .mat-mdc-optgroup-label {
      height: auto;
      line-height: 16px;
      padding: $size-3 $size-3 $size-2 $size-3;
      color: $tt9-color-neutral;
      font-family: $tt9-font-primary;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
  }

  &-select-panel-with-radio {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background: $tt9-color-neutral-0;

    .mat-mdc-option:not(.mat-mdc-option-multiple) {
      &:before {
        content: "";
        flex-shrink: 0 !important;
        width: 18px !important;
        height: 18px !important;
        margin-right: 12px !important;
        background: $tt9-color-neutral-0 !important;
        border: 2px solid $tt9-color-neutral-400 !important;
        border-radius: 100% !important;
        position: static;
        display: flex;
        box-sizing: border-box;
      }

      &.mdc-list-item--selected:before {
        content: "";
        background: $tt9-color-primary !important;
        border-color: $tt9-color-primary !important;
      }

      &.mdc-list-item--selected:after {
        position: absolute;
        content: "";
        background: $tt9-color-neutral-0 !important;
        border-color: $tt9-color-neutral-0 !important;
        border-radius: 50%;
        left: 13.5px;
        top: calc(50% - 3.5px);
        width: 7px;
        height: 7px;
      }

      &.mat-active:not(.mat-option-disabled):before,
      &:focus:not(.mat-option-disabled):before,
      &:hover:not(.mat-option-disabled):before {
        border-color: $tt9-color-primary !important;
      }

      &.mat-option-disabled:before {
        border-color: $tt9-color-neutral-100 !important;
      }

      &.mat-selected.mat-option-disabled:before {
        background: $tt9-color-neutral-100 !important;
        border-color: $tt9-color-neutral-100 !important;
      }

      &.mdc-list-item--selected {
        background: transparent !important;
        color: $tt9-color-primary;
      }

      &:hover {
        background: $tt9-color-neutral-50 !important;
      }

      &.mat-option-disabled.mat-selected {
        background: none !important;
      }

      &.mat-option-disabled {
        color: $tt9-color-neutral-300;
      }
    }
  }

  &-select-panel-with-checkboxes {
    .mat-mdc-option.mdc-list-item--disabled .mdc-list-item__primary-text {
      opacity: 1;
    }

    &.with-prefix {
      margin-left: 0 !important;
    }
  }

  &-select {
    .mat-mdc-select-value {
      color: $tt9-color-neutral;
    }

    .mat-mdc-select-arrow {
      color: $tt9-color-neutral;
    }
  }
}
