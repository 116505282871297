@import "mixins/button";

@mixin buttons {
  &-btn {
    @include button-filled($color-primary-shades, 900);
  }

  &-btn-outline {
    @include button-outline($color-primary-shades, 100);
  }

  &-btn-ghost {
    @include button-ghost($color-secondary-shades);
    padding-left: 0;
    padding-right: 0;
  }

  &-btn-ghost-primary {
    @include button-ghost($color-primary-shades, 900, 800, 900);
    padding-left: 0;
    padding-right: 0;
  }

  &-btn-ghost-danger {
    @include button-ghost($color-error-shades, 500);
    padding-left: 0;
    padding-right: 0;
  }

  &-btn-ghost-success {
    @include button-ghost($color-success-shades, 700);
    padding-left: 0;
    padding-right: 0;
  }

  &-btn-ghost-neutral {
    @include button-ghost($color-neutral-shades, 700, 800, 900);
    padding-left: 0;
    padding-right: 0;
  }

  &-btn-icon {
    @include button-ghost($color-neutral-shades, 700);
    padding: 0;

    mat-icon {
      font-size: 24px;
    }
  }

  &-btn-icon-accent {
    @include button-ghost($color-secondary-shades, 100);
    padding: 0;

    mat-icon {
      font-size: 24px;
    }
  }

  &-btn-icon-secondary {
    @include button-outline($color-secondary-shades, "", 600, 700);
    padding: 0;
    border-radius: 50% !important;

    mat-icon {
      font-size: 24px;
      color: $color-neutral-100;
    }
  }

  &-filters-btn {
    width: 40px;
    height: 40px;
    padding: 7px;
    background: $color-neutral-100;
    border: 1px solid $color-neutral-600;
    border-radius: $border-radius;
    cursor: pointer;

    mat-icon {
      color: $color-neutral-700;
      transition: all 0.3s;
    }

    &:hover {
      mat-icon {
        color: $color-neutral-900;
      }
    }
  }

  &-btn-rounded {
    @include button-ghost($color-neutral-shades, 900, 900, 900);
    height: 40px;
    width: 40px;
    justify-content: center;
    border-radius: 50% !important;

    &.selected {
      color: $color-neutral-100 !important;
      background: $color-secondary-500;
      pointer-events: none;
    }

    &.medium {
      height: 32px;
      width: 32px;
      padding-top: 4px;
      padding-bottom: 4px;
      background-color: $color-primary-700;

      &:hover {
        background-color: $color-primary-700;
        mat-icon {
          color: $color-neutral-900;
        }
      }

      i {
        font-size: 24px;
        color: $color-neutral-100;
      }
    }
  }

  &-snack-bar-confirm-btn {
    @include button-filled($color-secondary-shades, 400, 100, 100);
    color: $color-neutral-100;

    &:hover {
      color: $color-secondary-500;
    }
  }

  &-snack-bar-action-btn {
    @include button-ghost($color-neutral-shades, 100, 100, 100);
    color: $color-neutral-100;
  }
}
