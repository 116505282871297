@mixin dialog {
  &-dialog-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px 36px;
    border-bottom: 1px solid $color-neutral-600;

    &.no-border {
      border-bottom: 0;
    }

    &-image {
      width: 128px;
      height: 128px;
      border-radius: 50%;
      margin-right: 24px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &-dialog-actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 36px;
    border-top: 1px solid $color-neutral-600;
  }

  &-dialog {
    mat-dialog-container {
      &.mat-mdc-dialog-container {
        padding: 0 !important;
        border-radius: $border-radius !important;
        box-shadow: none !important;
        background: inherit !important;

        .mdc-dialog__container {
          .mdc-dialog__surface {
            border-radius: 8px;
            box-shadow: 0 8px 8px rgba(0, 0, 0, 0.16);
          }
        }
      }
    }
  }

  &-dialog-fit {
    mat-dialog-container {
      &.mat-dialog-container {
        padding: 0 !important;
        border-radius: $border-radius !important;
        box-shadow: 0 8px 8px rgba(0, 0, 0, 0.16);
        height: fit-content;
        overflow: hidden;
      }
    }
  }
}
