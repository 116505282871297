@import "../variables";

@mixin breadcrumb-container {
  display: inline-block;
  margin-top: 26px;
}

@mixin breadcrumbs {
  &-breadcrumbs {
    display: flex;
    margin: 0;
    width: 100%;
    box-sizing: border-box;

    .breadcrumb {
      display: flex;
      color: $color-neutral-400;
      font-size: 14px;
      line-height: 20px;
      max-width: 150px;
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &-tooltip {
        text-transform: capitalize;
      }

      &-empty {
        color: $color-secondary-500;
      }

      &-link {
        text-decoration: none;
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          text-decoration: underline;
        }
      }

      & a {
        color: $color-secondary-500;
      }

      &:last-child a {
        color: $color-neutral-700;
        pointer-events: none;
      }

      &:not(:last-child) a {
        cursor: pointer;
      }

      &:not(:first-child):before {
        font-family: "Material Icons";
        content: "keyboard_arrow_right";
        color: $color-secondary-500;
        font-size: 24px;
        line-height: 19px;
        margin: 0 4px;
      }

      &:not(:first-child):not(:last-child):before {
        content: "keyboard_arrow_right";
      }

      &:not(:first-child):last-child:before {
        content: "keyboard_arrow_right";
      }
    }
  }
}
