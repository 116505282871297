// Color primary shades
$color-primary: #330e70;
$color-primary-shades: (
  "": $color-primary,
  100: #f3edfd,
  200: #dbc9f8,
  300: #b792f2,
  400: #925ceb,
  500: #7a37e6,
  600: #641bda,
  700: #5316b6,
  800: #431291,
  900: #330e70,
);
$color-primary-100: map-get($color-primary-shades, 100);
$color-primary-200: map-get($color-primary-shades, 200);
$color-primary-300: map-get($color-primary-shades, 300);
$color-primary-400: map-get($color-primary-shades, 400);
$color-primary-500: map-get($color-primary-shades, 500);
$color-primary-600: map-get($color-primary-shades, 600);
$color-primary-700: map-get($color-primary-shades, 700);
$color-primary-800: map-get($color-primary-shades, 800);
$color-primary-900: map-get($color-primary-shades, 900);

// Color primary second shades
$color-secondary: #4f68ea;
$color-secondary-shades: (
  "": $color-secondary,
  100: #e8ecff,
  200: #b9c8fc,
  300: #95a9f8,
  400: #798ff2,
  500: #4f68ea,
  600: #394ec9,
  700: #2738a8,
  800: #192587,
  900: #0f1870,
);
$color-secondary-100: map-get($color-secondary-shades, 100);
$color-secondary-200: map-get($color-secondary-shades, 200);
$color-secondary-300: map-get($color-secondary-shades, 300);
$color-secondary-400: map-get($color-secondary-shades, 400);
$color-secondary-500: map-get($color-secondary-shades, 500);
$color-secondary-600: map-get($color-secondary-shades, 600);
$color-secondary-700: map-get($color-secondary-shades, 700);
$color-secondary-800: map-get($color-secondary-shades, 800);
$color-secondary-900: map-get($color-secondary-shades, 900);

// Color primary neutral shades
$color-neutral: #151618;
$color-neutral-shades: (
  "": $color-neutral,
  100: #ffffff,
  200: #fafafa,
  300: #f5f5f5,
  400: #f0f0f0,
  500: #ececec,
  600: #d9d9dd,
  700: #9ea0a9,
  800: #41464d,
  900: #151618,
);
$color-neutral-100: map-get($color-neutral-shades, 100);
$color-neutral-200: map-get($color-neutral-shades, 200);
$color-neutral-300: map-get($color-neutral-shades, 300);
$color-neutral-400: map-get($color-neutral-shades, 400);
$color-neutral-500: map-get($color-neutral-shades, 500);
$color-neutral-600: map-get($color-neutral-shades, 600);
$color-neutral-700: map-get($color-neutral-shades, 700);
$color-neutral-800: map-get($color-neutral-shades, 800);
$color-neutral-900: map-get($color-neutral-shades, 900);

// Color primary success shades
$color-success: #05f0a5;
$color-success-shades: (
  "": $color-success,
  100: #ccfeda,
  200: #9afdc0,
  300: #67faae,
  400: #41f6aa,
  500: #05f0a5,
  600: #03cea1,
  700: #02ac97,
  800: #018b87,
  900: #006a73,
);
$color-success-100: map-get($color-success-shades, 100);
$color-success-200: map-get($color-success-shades, 200);
$color-success-300: map-get($color-success-shades, 300);
$color-success-400: map-get($color-success-shades, 400);
$color-success-500: map-get($color-success-shades, 500);
$color-success-600: map-get($color-success-shades, 600);
$color-success-700: map-get($color-success-shades, 700);
$color-success-800: map-get($color-success-shades, 800);
$color-success-900: map-get($color-success-shades, 900);

// Color primary warning shades
$color-warning: #f9b247;
$color-warning-shades: (
  "": $color-warning,
  100: #fef6da,
  200: #feeab5,
  300: #fdda90,
  400: #fbcb74,
  500: #f9b247,
  600: #d68e33,
  700: #b36e23,
  800: #905116,
  900: #773c0d,
);
$color-warning-100: map-get($color-warning-shades, 100);
$color-warning-200: map-get($color-warning-shades, 200);
$color-warning-300: map-get($color-warning-shades, 300);
$color-warning-400: map-get($color-warning-shades, 400);
$color-warning-500: map-get($color-warning-shades, 500);
$color-warning-600: map-get($color-warning-shades, 600);
$color-warning-700: map-get($color-warning-shades, 700);
$color-warning-800: map-get($color-warning-shades, 800);
$color-warning-900: map-get($color-warning-shades, 900);

// Color primary danger shades
$color-error: #af5762;
$color-error-shades: (
  "": $color-error,
  100: #fbe7e1,
  200: #f7ccc5,
  300: #e7a5a1,
  400: #cf8083,
  500: #af5762,
  600: #963f52,
  700: #7d2b45,
  800: #651b39,
  900: #531031,
);
$color-error-100: map-get($color-error-shades, 100);
$color-error-200: map-get($color-error-shades, 200);
$color-error-300: map-get($color-error-shades, 300);
$color-error-400: map-get($color-error-shades, 400);
$color-error-500: map-get($color-error-shades, 500);
$color-error-600: map-get($color-error-shades, 600);
$color-error-700: map-get($color-error-shades, 700);
$color-error-800: map-get($color-error-shades, 800);
$color-error-900: map-get($color-error-shades, 900);

// Fonts
$font-primary: Roboto, "Helvetica Neue", sans-serif;

// Sizes
$border-radius: 8px;
$border-radius-modal: 6px;
$border-radius-small: 4px;
$border-radius-large: 24px;
$main-sidenav-width: 96px;
$header-height: 64px;
$sub-header-height: 130px;
$title-height: 60px;
$content-height: calc(100vh - #{$header-height}); // without header
$empty-content-height: calc(100vh - #{$header-height} - #{$title-height}); // without header and title
$question-panel-max-width: 1068px;
$activity-navigation-panel-width: 324px;
$activity-navigation-panel-inside-width: 248px;
$brainstorm-card-width-breakpoint: 1675px;

// Statuses & Categorization
$status-completed: #56c568;
$status-info: #3fa2f7;
$status-negative: #eb5757;
$status-warning: #ffc400;
$status-inactive: #d4d4d4;

// Backgrounds
$color-background-primary: $color-neutral-200;
$color-background-light: $color-neutral-100;

// Box shadow
$color-box-shadow-primary: rgba(0, 0, 0, 0.16);

// Typography
$color-text: $color-neutral;
$color-text-gray: $color-neutral-500;
$color-text-light: $color-neutral-600;
$color-text-accent: $color-neutral-100;
$color-text-disabled: $color-neutral-600;
$color-text-dark: $color-neutral;
