@mixin chart {
  &-chart {
    &-figure {
      position: relative;
      font-size: 0;
    }

    &-legend {
      font-size: 12px;

      &-circle {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin-right: 4px;
        flex-shrink: 0;
      }

      &-item {
        display: flex;
        align-items: center;
      }
    }

    &-empty-circle {
      background: $color-neutral-600;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }

    &-tooltip {
      position: absolute;
      width: fit-content;
      visibility: hidden;
      background: $color-neutral-800;
      color: $color-neutral-100;
      line-height: 18px;
      padding: 8px 12px;
      border-radius: 4px;
      z-index: 999;
      font-size: 12px;

      .accenture-chart-legend-item {
        margin: 2px 0;
        max-width: 140px;
        word-break: break-word;
      }
    }

    &-axis-tooltip {
      max-width: 286px - (16px * 2); // legend width - 16px padding for tooltip
      word-break: break-word;
    }
  }
}
