@mixin attribute-chip {
  &-attribute-chip {
    display: flex !important;
    align-items: center !important;
    padding: $size-2 $size-3 !important;
    height: 32px !important;
    width: fit-content;
    background-color: $tt9-color-primary-50 !important;
    border: 1px solid $tt9-color-accent-300 !important;
    border-radius: 100px !important;
    box-sizing: border-box;
    white-space: nowrap !important;
    transition: all 0.3s;

    @include font-style(14px, 1px, 18px, 500);
    text-transform: capitalize;

    &.mat-mdc-chip {
      .mdc-evolution-chip {
        &__action {
          padding: 0;
        }

        &__text-label {
          @include font-style(14px, 1px, 18px, 500);
          font-family: $tt9-font-primary !important;
        }
      }
    }

    &:not(.disabled):not(:disabled) {
      &:hover {
        background-color: $tt9-color-accent-100 !important;
        cursor: pointer !important;
      }

      &:active,
      &.selected {
        background-color: $tt9-color-accent-300 !important;
        color: $tt9-color-neutral-0 !important;
      }
    }

    &.success,
    &.success accenture-icon {
      padding-left: 4px;
      background-color: $tt9-color-success-50 !important;
      border-color: $tt9-color-success-300 !important;
      color: $tt9-color-success-300 !important;

      &:not(.disabled):not(:disabled) {
        &:hover,
        &:hover accenture-icon {
          background-color: $tt9-color-success-200 !important;
        }

        &:active,
        &.selected,
        &:active accenture-icon,
        &.selected accenture-icon {
          background-color: $tt9-color-success-300 !important;
          color: $tt9-color-neutral-0 !important;

          .color-success {
            color: $tt9-color-neutral-0 !important;
          }
        }
      }
    }

    &.error,
    &.error accenture-icon {
      padding-left: 4px;
      background-color: $tt9-color-error-100 !important;
      border-color: $tt9-color-error-300 !important;
      color: $tt9-color-error-300 !important;
      transition: all 0.3s;

      .color-error {
        color: $tt9-color-error-300 !important;
      }

      &:not(.disabled):not(:disabled) {
        &:hover,
        &:hover accenture-icon {
          background-color: $tt9-color-error-200 !important;
        }

        &:active,
        &.selected,
        &:active accenture-icon,
        &.selected accenture-icon {
          background-color: $tt9-color-error-300 !important;
          color: $tt9-color-neutral-0 !important;

          .color-error {
            color: $tt9-color-neutral-0 !important;
          }
        }
      }
    }

    &:disabled,
    &.disabled,
    &.disabled accenture-icon {
      padding-left: 4px;
      background-color: $tt9-color-neutral-50 !important;
      border-color: $tt9-color-neutral-400 !important;
      color: $tt9-color-neutral-500 !important;
      cursor: default;
      pointer-events: none;

      .mdc-evolution-chip__text-label {
        color: $tt9-color-neutral-500 !important;
      }

      .mat-mdc-chip-focus-overlay {
        display: none;
      }

      .color-error,
      .color-success {
        color: $tt9-color-neutral-400 !important;
      }
    }

    &.without-border {
      border: none !important;
    }

    &:hover {
      cursor: default !important;
    }

    accenture-icon,
    accenture-icon .color-error,
    accenture-icon .color-success {
      transition: all 0.3s;
    }

    &:not(.mat-mdc-chip-disabled):active {
      box-shadow: none !important;
    }
  }
}
