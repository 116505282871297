@import "mixins/radio-button";

@mixin radio-button {
  &-radio-button.mat-mdc-radio-button {
    .mdc-label {
      display: inline-block;
      font-family: $tt9-font-primary;
      padding-left: $size-2;
      font-size: 16px;
      cursor: pointer !important;
      min-width: 168px !important;
    }

    .mat-mdc-select {
      font-family: $tt9-font-primary;
      font-size: 16px;
      letter-spacing: 0.15px;

      .mat-mdc-select-placeholder {
        color: $tt9-color-neutral;
      }

      .mat-mdc-select-arrow-wrapper {
        width: 18px;
      }
    }

    &.mat-primary {
      @include radio-button-template($tt9-color-primary-shades, 200, 50);
    }

    &.mat-accent {
      @include radio-button-template($tt9-color-secondary-shades, 400, 200);
    }

    &.width-auto {
      width: auto;
    }
  }
}
