@mixin invisible-input {
  &-invisible-input {
    width: 100%;
    position: relative;

    .mat-mdc-text-field-wrapper {
      min-width: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    .mat-mdc-form-field-flex {
      padding: 0 !important;
    }

    .mat-mdc-form-field-infix {
      font-family: $tt9-font-primary;
      border: none;
      padding: 2px 0 !important;
      min-height: auto;
    }
    .mdc-notched-outline {
      &__leading,
      &__notch,
      &__trailing {
        border: none !important;
      }
    }

    &.date-form-field {
      @include icons-color($color-neutral-700);

      .mat-mdc-form-field-icon-suffix {
        display: flex;
        right: -8px !important;
        position: relative;
        cursor: pointer;
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      position: absolute !important;
      padding: 0 !important;
      top: 100%;
      font-size: 12px;
      font-family: $tt9-font-primary;
      line-height: 18px;
      letter-spacing: 0.15px;

      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        padding: 0 !important;
      }
    }

    &.has-hint {
      .mat-mdc-form-field-subscript-wrapper {
        position: relative !important;

        .mat-mdc-form-field-hint-wrapper,
        .mat-mdc-form-field-error-wrapper {
          position: relative !important;
        }
      }
    }

    &.mat-form-field-invalid .mat-mdc-form-field-bottom-align::before {
      content: none;
    }

    &.mat-focused.has-hint,
    &.mat-form-field-invalid {
      .mat-mdc-form-field-subscript-wrapper,
      .mat-mdc-form-field-error-wrapper {
        position: relative !important;
      }
    }

    .mat-mdc-form-field-icon-prefix .mat-icon-button,
    .mat-mdc-form-field-icon-suffix .mat-icon-button {
      height: 24px !important;
      width: 24px !important;
    }

    .mat-mdc-form-field-icon-prefix .mat-mdc-button-base,
    .mat-mdc-form-field-icon-suffix .mat-mdc-button-base {
      height: 24px !important;
      width: 24px !important;
      padding: 0 !important;
    }

    .mat-mdc-form-field-error {
      color: $color-error !important;
    }

    input {
      &::placeholder {
        color: $tt9-color-neutral-500;
        -webkit-text-fill-color: $tt9-color-neutral-500;
      }
    }

    &:not(.mat-focused) .mat-hint-limit {
      display: none;
    }
  }
}
