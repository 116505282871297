.text-primary {
  color: $color-primary;
}

.text-secondary {
  color: $color-secondary;
}

.text-accent {
  color: $color-neutral-100 !important;
}

.text-neutral {
  color: $color-neutral-700;
}

.text-default {
  color: $color-neutral-900;
}

.text-success {
  color: $color-success-700;
}

.text-error {
  color: $color-error-500;
}

.text-gray {
  color: $color-text-gray;
}

.text-disabled {
  color: $color-text-disabled;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-highlighted {
  background-color: $color-secondary-200;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-center {
  text-align: center;
}

.text-transparent {
  opacity: 0.5;
}

.text-spaced {
  letter-spacing: 2px;
}

.text-bold {
  font-weight: bold;
}

.text-left {
  text-align: left;
}

.no-link {
  text-decoration: none;
  display: block;
}

.required {
  color: $color-error;
}

.icon-small {
  font-size: 16px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.text-overflow-ellipsis-line-clamp-basic {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.break-word {
  word-break: break-word;
}

.break-all-word {
  word-break: break-all;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  // fix double tooltip in Safari
  &::after {
    content: "";
    display: block;
  }

  &-1 {
    @extend .text-overflow-ellipsis-line-clamp-basic;
    -webkit-line-clamp: 1;
  }

  &-2 {
    @extend .text-overflow-ellipsis-line-clamp-basic;
    -webkit-line-clamp: 2;
  }

  &-3 {
    @extend .text-overflow-ellipsis-line-clamp-basic;
    -webkit-line-clamp: 3;
  }

  &-4 {
    @extend .text-overflow-ellipsis-line-clamp-basic;
    -webkit-line-clamp: 4;
  }
}

.text-expandable {
  @extend .text-overflow-ellipsis;
  line-height: 24px !important;
  padding-right: 48px;
  position: relative;
  cursor: pointer;

  mat-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 16px;
    color: $color-neutral-700;
  }
}

.break-spaces {
  white-space: break-spaces;
}
