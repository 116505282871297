@mixin slider-colored-track($colors, $track-color: $tt9-color-neutral-100) {
  .mdc-slider__track--active_fill {
    border: 0;
    background: linear-gradient(90deg, map-get($colors, 0) 0%, map-get($colors, 1) 100%) !important;
  }

  .mdc-slider__value-indicator {
    background-color: map-get($colors, 1) !important;
  }
}

@mixin slider {
  &-slider.mat-mdc-slider {
    height: 40px;
    margin: 0;
    width: calc(100% - 24px);
    left: 12px;

    &-visual-thumb .mat-ripple {
      height: 0;
      width: 0;
    }

    // default color
    @include slider-colored-track(map-get($tt9-accent-colors, 9));

    .mdc-slider {
      &__track {
        height: 24px;
        width: calc(100% + 24px);
        left: -12px;

        &--inactive,
        &--active {
          height: 24px;
        }

        &--inactive {
          background-color: $tt9-color-neutral-100;
          opacity: 1;
          top: -1px;
        }
      }

      &__value-indicator {
        opacity: 1;
        box-sizing: border-box;
        border: 0px;
        padding: $size-2;
        height: 32px;
        min-width: 32px;
        width: auto;
        color: $tt9-color-neutral-0;
        border-radius: 16px;
        transform: scale(1);
        justify-content: center;

        &:after {
          content: "";
          position: absolute;
          top: $size-1;
          left: $size-1;
          right: $size-1;
          bottom: $size-1;
          border: 1px solid $tt9-color-neutral-0;
          border-radius: 16px;
          height: auto;
          width: auto;
        }

        &:before {
          display: none;
        }

        &-container {
          bottom: 13px;
          z-index: 1;
          pointer-events: none !important;
          transform: translate(-100%, 0);
        }

        &-text {
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 0.2px;
          font-weight: normal;
          font-family: $tt9-font-primary;
        }
      }

      &__thumb {
        left: -10px;

        &-knob {
          display: none;
        }
      }

      &__input {
        padding: 0px;
        width: calc(100% + 48px);
        left: -$size-4;
      }
    }

    .mat-mdc-focus-indicator {
      display: none;
    }

    // disabled
    &.mdc-slider--disabled {
      opacity: 1;
      width: calc(100% - 24px);
      left: 12px;

      $disabled-colors: (
        0: $tt9-color-neutral-300,
        1: $tt9-color-neutral-300,
      );
      @include slider-colored-track($disabled-colors, $tt9-color-neutral-100);

      .mdc-slider {
        &__input {
          cursor: default;
        }

        &__track {
          &--inactive {
            background-color: $tt9-color-neutral-100;
            opacity: 1;
          }
        }
      }
    }
  }
}
