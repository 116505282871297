@mixin skeleton($width: 100%, $height: 100%, $radius: 0px, $color: #f0f0f0, $speed: 2s) {
  width: $width;
  height: $height;
  border-radius: $radius;
  background-color: $color;
  background-size: 150% 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, $color 0%, $color 20%, $tt9-color-neutral-0 40%, $color 60%, $color 100%);
  background-image: -webkit-linear-gradient(
    to right,
    $color 0%,
    $color 20%,
    $tt9-color-neutral-0 40%,
    $color 60%,
    $color 100%
  );
  background-image: -moz-linear-gradient(
    to right,
    $color 0%,
    $color 20%,
    $tt9-color-neutral-0 40%,
    $color 60%,
    $color 100%
  );
  background-image: -ms-linear-gradient(
    to right,
    $color 0%,
    $color 20%,
    $tt9-color-neutral-0 40%,
    $color 60%,
    $color 100%
  );
  background-image: -o-linear-gradient(
    to right,
    $color 0%,
    $color 20%,
    $tt9-color-neutral-0 40%,
    $color 60%,
    $color 100%
  );
  animation: skeleton-flash 2s ease-in-out infinite;

  @keyframes skeleton-flash {
    0% {
      background-position: 300% 0;
    }
    100% {
      background-position: -300% 0;
    }
  }
}
