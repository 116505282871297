@mixin snackbar($background, $width: 396px, $padding: $size-3) {
  margin: 40px !important;
  background: $background !important;
  border-radius: $border-radius !important;
  padding: $padding $size-5 $padding $padding !important;
  min-width: $width !important;
  max-width: $width !important;
  box-shadow: 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11) !important;
}

@mixin snackbar-icon($background-color, $icon-color: $tt9-color-neutral-0) {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: $background-color;
  color: $icon-color;
  flex-shrink: 0;
}
