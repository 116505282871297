@font-face {
  font-family: 'Graphik';
  src: url('./graphik-regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./graphik-medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}
