@mixin activity-view {
  &-activity {
    &-container {
      display: flex;
      height: 100%;
    }

    &-header {
      &-container {
        display: flex;
        flex-direction: column;
        background-color: $color-neutral-100;

        &:not(.summary) {
          padding-bottom: 8px;
          border-bottom: $color-neutral-600 solid 1px;
        }
      }

      &-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }

    &-actions {
      display: flex;
      padding: 16px 36px 24px 20px;
      align-items: center;

      button {
        margin-left: 16px;
      }
    }

    &-navigation {
      min-width: $activity-navigation-panel-width;
      max-width: $activity-navigation-panel-width;
      height: 100%;
      flex-shrink: 0;
      background: $color-neutral-100;
      border-right: 1px solid $color-neutral-600;
      overflow-y: auto;

      &.inside {
        min-width: $activity-navigation-panel-inside-width;
        max-width: $activity-navigation-panel-inside-width;
      }
    }

    &-content {
      padding: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    }

    &-items-content {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      flex-direction: column;
      box-sizing: border-box;
      overflow-y: auto;
    }
  }
}
