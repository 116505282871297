@mixin content {
  &-content {
    width: $content-inner-width;

    .agenda-opened:not(.leader-view) & {
      width: $content-inner-width-without-agenda;
    }

    .leader-view:not(.agenda-opened) & {
      width: $content-inner-width-without-toolbar;
    }

    .agenda-opened.leader-view & {
      width: $content-inner-width-without-agenda-and-toolbar;
    }
  }
}
