@mixin form-field-input($color: $tt9-color-neutral, $color-placeholder: $tt9-color-neutral-500, $line-height: 24px) {
  .mat-mdc-input-element,
  .mat-mdc-select {
    color: $color;
    background-color: transparent !important;
    font-family: $tt9-font-primary;
    font-size: 16px;
    line-height: $line-height;
    min-height: $line-height;
    letter-spacing: 0;

    &:disabled {
      color: rgba($color, 0.38);
    }

    // specific for safari issue
    // TODO: need to find the solution that will change the color of disabled input value to the $color
    // and will not affect the color of the placeholder
    // &:disabled {
    //   -webkit-text-fill-color: $color;
    // }

    // Keep rules below separate to really apply them in browsers
    &::placeholder {
      color: $color-placeholder !important;
    }

    &:disabled::placeholder {
      color: $tt9-color-neutral-400 !important;
    }

    // for webkit browsers
    &::-webkit-input-placeholder {
      color: $color-placeholder !important;
    }

    &:disabled::-webkit-input-placeholder {
      color: $tt9-color-neutral-400 !important;
    }

    // firefox 19+
    &::-moz-placeholder {
      color: $color-placeholder !important;
    }

    &:disabled::-moz-placeholder {
      color: $tt9-color-neutral-400 !important;
    }

    // ie10+
    &:-ms-input-placeholder {
      color: $color-placeholder !important;
    }

    &:disabled:-ms-input-placeholder {
      color: $tt9-color-neutral-400 !important;
    }

    // Remove the small eyes that appear by default in the password box of IE EDGE*/
    &[type="password"]::-ms-reveal,
    &[type="password"]::-ms-clear {
      display: none;
    }
  }
}
