@mixin dnd {
  &-dnd {
    &-preview {
      position: relative;

      &-content {
        position: absolute;
        height: 40px;
        border-radius: 20px;
        background-color: $tt9-color-neutral;
        color: $tt9-color-neutral-0;
        display: flex;
        align-items: center;
        padding: 0 20px;
        top: -8px;
        left: -8px;
        white-space: nowrap;
        max-width: 218px;
        overflow: hidden;
        text-overflow: ellipsis;

        &.small {
          height: 28px;
        }
      }
    }

    &-initial-placeholder {
      background-color: $tt9-color-neutral-50;
      box-sizing: border-box;
      border: 2px dashed $tt9-color-neutral-300;
      border-radius: $size-2;

      &.small {
        border-width: 1px;
      }
    }

    &-placeholder {
      &-before,
      &-after {
        position: relative;

        &:after {
          position: absolute;
          content: "";
          right: 0;
          left: 0;
          bottom: 3px;
          border: 1px solid $tt9-color-accent;
        }

        &.placeholder-small {
          &:after {
            bottom: 1px;
          }
        }
      }
    }
  }
}
