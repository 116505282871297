@mixin table {
  &-table {
    font-size: 14px;
    border-collapse: separate;
    border-spacing: 0 $size-1;
    table-layout: auto;
    position: relative;
    transform: translateY(-4px);

    &-footer {
      z-index: 5;
      bottom: 0;
      position: sticky;
      transform: translateY(-4px);

      &:before {
        position: absolute;
        content: "";
        bottom: -16px;
        right: -5px;
        background-color: $tt9-color-neutral-0;
        z-index: 4;
        width: 100%;
        height: 16px;
      }
    }

    .hide-sticky {
      z-index: -1;
      position: relative;
    }

    .first-column {
      position: absolute;
      bottom: -17px;
      left: -5px;
      background-color: $tt9-color-neutral-0;
      z-index: 4;
      width: 10px;
      height: 16px;
    }

    & .last-row-cell {
      position: relative;

      &:before {
        position: absolute;
        content: "";
        bottom: -20px;
        right: -5px;
        background-color: $tt9-color-neutral-0;
        z-index: 5;
        width: 12px;
        height: 19px;
      }
    }

    &-resize {
      position: absolute;
      top: 0;
      width: 12px;
      background-color: transparent;
      right: -6px;
      z-index: 2;

      &-line {
        margin-left: 5px;
        width: 1px;
        height: 100%;
        background-color: transparent;
      }

      &.last {
        top: 4px;
      }

      &.hide-left,
      &.hide-right {
        opacity: 0;

        &:hover {
          cursor: default;
        }
      }

      &:hover {
        z-index: 2;
      }

      &:hover:not(.disabled) {
        cursor: col-resize;
      }

      &:hover &-line {
        background-color: $tt9-color-primary;
      }

      .table-add-icon {
        position: absolute;
        opacity: 0;
        left: -6px;

        .icon-add {
          cursor: pointer;
          border-radius: 100%;
          background-color: $tt9-color-accent;
          color: $tt9-color-neutral-0;
          z-index: 4;
        }
      }

      &:hover .table-add-icon {
        opacity: 1;
      }
    }

    &-resize.visible-line {
      position: absolute;
      top: 0;
      width: 12px;
      background-color: transparent;
      right: -6px;
      z-index: 1;

      &.last {
        top: 4px;
      }

      &.hide-left,
      &.hide-right {
        opacity: 0;
      }

      &-line {
        margin-left: 6px;
        width: 1px;
        height: 100%;
        background-color: $tt9-color-primary;
      }
    }

    &-container {
      max-height: 100%;
      overflow: auto;
      border-radius: 8px;
      display: flex;

      // shadows for sticky rows and columns
      &.horizontal-shadow-visible .horizontal-shadow {
        &:before {
          content: "";
          position: absolute;
          box-shadow: 0.6px 0.6px 1.8px rgba($tt9-color-neutral, 0.11), 0 6px 7.2px rgba($tt9-color-neutral, 0.13);
          height: 100%;
          width: 100%;
          bottom: 0;
          left: 0;
          z-index: -1;
        }
      }

      &.vertical-shadow-visible {
        & .vertical-shadow {
          &:before {
            content: "";
            position: absolute;
            box-shadow: 0.6px 0.6px 1.8px rgba($tt9-color-neutral, 0.11), 6px 0 7.2px rgba($tt9-color-neutral, 0.13);
            height: 100%;
            width: 100%;
            right: 0;
            top: 0;
            z-index: -1;
          }
        }
      }

      &.horizontal-shadow-visible:not(.vertical-shadow-visible) {
        .column-sticky {
          z-index: inherit;
        }
      }
    }

    // row hover
    &:not(.no-row-hovering) {
      tbody {
        tr.hover,
        tr:hover {
          td {
            background: $tt9-color-neutral-50;
          }
        }
      }
    }

    // disable row hover
    &.no-row-hovering {
      tbody {
        td.hover,
        td:hover {
          background: $tt9-color-neutral-50;
        }
      }
    }

    th {
      vertical-align: top;
      color: $tt9-color-neutral !important;
      padding-top: 12px;
      height: 72px;

      &.editable {
        padding: 0 $size-2 $size-2 !important;
        height: 86px;
        .tt9-form-field.mat-form-field-appearance-outline.no-label:not(.large).custom-width.has-hint
          .mat-mdc-text-field-wrapper {
          margin: 0 !important;
        }

        .tt9-form-field.mat-form-field-appearance-outline.no-label:not(.large).custom-width.has-hint
          .mat-mdc-form-field-subscript-wrapper {
          padding: 0 !important;
          line-height: 14px !important;
          margin: 0px !important;
          height: 16px;
        }
      }

      &.has-error {
        background-color: $tt9-color-error-200 !important;
        border: 1px solid $tt9-color-error-300;
      }
    }

    td {
      vertical-align: middle;
      height: $cell-min-height;

      &.has-error {
        background-color: $tt9-color-error-200 !important;
        border: 1px solid $tt9-color-error-300;
      }

      &.editable {
        height: 74px;

        .tt9-form-field.mat-form-field-appearance-outline.no-label:not(.large).custom-width
          .mat-mdc-text-field-wrapper {
          margin: 0 !important;

          textarea {
            min-height: 24px !important;
            max-height: 24px !important;
            height: 24px !important;
          }
        }

        .tt9-form-field.mat-form-field-appearance-outline.no-label:not(.large).custom-width
          .mat-mdc-form-field-subscript-wrapper {
          padding: 0 !important;
          line-height: 14px !important;
          margin: 0px !important;
          height: 16px;
        }
      }
    }

    th,
    td {
      color: $tt9-color-neutral;
      background: $tt9-color-neutral-0;
      font-size: 14px;
      position: relative;
      box-sizing: border-box;
      border: 1px solid $tt9-color-neutral-200;
      border-width: 1px 0 1px 0;
      padding: $size-2 $size-3;

      &.drag-cell,
      &.num-cell,
      &.action-cell {
        padding: 0;
        min-width: 40px;
        max-width: 40px;
        width: 40px;
        vertical-align: middle;
      }

      &.editable {
        padding: $size-2;
      }

      &.action-cell {
        position: sticky;
        right: 0;

        &:last-child::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          height: 24px;
          background-color: transparent;
          left: -1px;
          border-right: 1px solid $tt9-color-neutral-200;
          z-index: -1;
        }
      }

      &:not(.num-cell):not(.drag-cell):not(.action-cell):not(.full-width):not(.column-bookmark):not(
          .column-sequence
        ):not(.resizable-width):not(.ballot-item-cell):not(.attribute-cell) {
        min-width: $cell-min-width;
        max-width: $cell-max-width;
      }

      &:first-child {
        border-width: 1px 0 1px 8px;
        border-color: $tt9-color-neutral-200 $tt9-color-neutral-200 $tt9-color-neutral-200 $tt9-color-accent;
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-right-width: 1px;
      }

      &:not(.no-border):not(:last-child):after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        height: 24px;
        width: 1px;
        background-color: $tt9-color-neutral-200;
      }

      &.resizable-width {
        min-width: initial;
        max-width: initial;
      }

      &.full-width {
        max-width: 720px;
      }

      &.hover {
        background-color: $tt9-color-neutral-50;
      }

      .column-content {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
      }

      &.dnd-disabled {
        border-color: $tt9-color-neutral-100;
      }

      .editable-text {
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid transparent;
        line-height: 22px !important;
        padding: 1px $size-2;

        &:hover {
          border-color: $tt9-color-primary;
        }

        &.disabled {
          cursor: default;
          border-color: transparent;
        }
      }

      .link-btn {
        cursor: pointer;
        padding: 2px 8px;
      }

      .mat-mdc-form-field {
        height: auto !important;
      }

      &.vertical-middle {
        vertical-align: middle !important;
      }
    }

    @-moz-document url-prefix() {
      tr {
        height: $cell-min-height;
      }
    }

    &.full-border {
      th,
      td {
        &:not(.no-border):not(:last-child):after {
          top: $size-3;
          bottom: $size-3;
          transform: none;
          height: auto;
        }
      }
    }

    thead {
      &.table-head-sticky {
        position: sticky;
        top: 0;
        z-index: 3;
      }

      tr:first-child {
        th:last-child {
          border-top-right-radius: 8px;
        }
      }

      tr:not(:first-child) {
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: -4px;
          height: 4px;
          width: 100%;
          left: 0;
          background-color: $tt9-color-neutral-0;
        }

        th {
          height: 40px;
          vertical-align: middle;
        }
      }

      &.summary-table-header {
        th {
          height: 56px;
          vertical-align: middle;
        }
      }
    }

    tbody:last-child {
      tr:last-child {
        td:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }

    .cell-sequence {
      padding: 0;
      text-align: center !important;
      width: 40px;
      min-width: 40px;
    }

    .column-numbered {
      text-align: center !important;
      width: 40px;
      min-width: 40px;
    }

    .column-sticky {
      position: sticky;
      left: 0;
      z-index: 2;
    }

    tfoot tr td {
      &::after {
        content: none !important;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }

    // Drag'n'drop styles
    &.is-dragging {
      cursor: grabbing;

      .table-add-column-between,
      .table-add-row-between {
        pointer-events: none;
      }
    }

    thead tr th.cdk-drag-placeholder {
      pointer-events: inherit !important;
      position: relative;
      box-sizing: border-box;

      .column-dnd-initial-header-placeholder {
        display: table-cell;
        background-color: $tt9-color-neutral-50;
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -2px;
        right: -1px;
        z-index: 2;
        border: 1px dashed $tt9-color-neutral-300;
        border-bottom: 0;

        &:hover {
          border-color: $tt9-color-primary;
          border-style: solid;
        }
      }

      &:last-child {
        .column-dnd-initial-header-placeholder {
          border-top-right-radius: 8px;
        }
      }
    }

    .cdk-drag-handle {
      cursor: grab;

      &.disabled {
        cursor: default;
        color: $tt9-color-neutral-200;
      }
    }

    // column source placeholder (header)
    .column-dnd-initial-header-placeholder {
      display: none;
    }

    // column source placeholder (cell)
    .column-dnd-initial-cell-placeholder {
      background-color: $tt9-color-neutral-50;
      position: absolute;
      top: -5px;
      bottom: -1px;
      left: -2px;
      right: -1px;
      z-index: 2;
      border-left: 1px dashed $tt9-color-neutral-300;
      border-right: 1px dashed $tt9-color-neutral-300;

      &.last-cell {
        border-bottom-right-radius: 8px;
      }

      &.last-row {
        border-bottom: 1px dashed $tt9-color-neutral-300;
      }

      &.hover {
        border-color: $tt9-color-primary;
        border-style: solid;
      }
    }

    // column target placeholder
    .column-dnd-placeholder {
      &-before,
      &-after {
        position: absolute;
        top: -1px;
        bottom: -5px;
        border: 1px solid $tt9-color-accent;
        width: 0;
        z-index: 4;
        box-sizing: border-box;

        &.last-row {
          bottom: -1px;
        }
      }

      &-before {
        left: -1px;
      }

      &-after {
        right: 0;
      }
    }

    // row target placeholder
    tr td {
      .row-dnd-placeholder {
        &-before,
        &-after {
          position: absolute;
          border: 1px solid $tt9-color-accent;
          left: -1px;
          right: -1px;
          height: 0;
          z-index: 4;
          box-sizing: border-box;
        }

        &-before {
          top: -4px;
        }

        &-after {
          bottom: -4px;
        }
      }

      &:first-child {
        .row-dnd-placeholder-before,
        .row-dnd-placeholder-after {
          left: -8px;
        }
      }
    }

    // row source placeholder
    tbody tr.cdk-drag-placeholder {
      position: relative;
      pointer-events: initial !important;

      td {
        border: 0;
        overflow: hidden;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $tt9-color-neutral-50;
          z-index: 1 !important;
          border: 1px dashed $tt9-color-neutral-300;
          border-width: 1px 0;
          box-shadow: none !important;
          height: auto !important;
        }

        &:first-child::before {
          border-radius: 8px 0 0 8px;
          border-left-width: 1px;
        }

        &:last-child::before {
          border-right-width: 1px;
        }
      }

      &:hover {
        td::before {
          border-color: $tt9-color-primary;
          border-style: solid;
        }
      }
    }

    // column preview box
    .column-dnd-preview {
      top: -24px !important;
      border: 4px dashed $tt9-color-neutral-300;
      box-sizing: border-box;
    }

    // row preview box
    .row-dnd-preview {
      left: -20px !important;
      border: 4px dashed $tt9-color-neutral-300;
      box-sizing: border-box;
    }

    // add column and row between buttons
    .table-add-column-between,
    .table-add-row-between {
      position: absolute;
      display: flex;
      align-items: center;
      opacity: 0;
      box-sizing: border-box;

      &.visible {
        opacity: 1;
      }

      &-icon {
        cursor: pointer;
        border-radius: 100%;
        background-color: $tt9-color-accent;
        color: $tt9-color-neutral-0;
        z-index: 3;
      }

      &-line {
        z-index: 2;
        flex-grow: 1;
        height: 0;
        border: 1px solid $tt9-color-accent;
      }
    }

    .table-add-row-between {
      top: -9px;
      left: -1px;
      right: -1px;
      height: 12px;

      &-line-spacer {
        height: 0;
        border: 1px solid $tt9-color-neutral-0;
        width: 16px;
      }
    }

    .table-add-column-between {
      z-index: 2;
      top: -5px;
      left: -6px;
      flex-direction: column;
      bottom: -1px;
      width: 12px;
    }

    thead tr:first-child th .table-add-column-between {
      top: 12px;
    }

    tbody tr td:first-child .table-add-row-between {
      left: 4px;
    }
  }
}

@mixin select-table {
  &-select-table {
    font-size: 14px;
    table-layout: auto;
    text-align: left;

    &-container {
      max-height: 100%;
      overflow-y: auto;
    }

    tbody {
      tr.hover,
      tr:hover {
        cursor: pointer;

        td {
          background: $tt9-color-neutral-50;
        }
      }
    }

    th {
      z-index: 3;
      padding: 16px 8px;
      color: $tt9-color-neutral-500 !important;
      border: none !important;
    }

    td {
      padding: 24px 8px;
    }

    th,
    td {
      background: $tt9-color-neutral-0;
      vertical-align: middle;
      box-sizing: border-box;
      min-width: $select-table-cell-min-width;
      border: 1px solid $tt9-color-neutral-200;
      border-width: 0 0 1px 0;

      &.resizable-width {
        min-width: initial;
        max-width: initial;
      }

      &.short-cell {
        min-width: 200px;
      }
    }

    .cell-select {
      width: 40px;
      min-width: 40px;
      text-align: center;
    }

    .cell-date {
      width: 120px;
      min-width: 120px;
    }

    .row-sticky {
      position: sticky;
      top: 0;
      z-index: 2;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid $tt9-color-neutral-200;
      }
    }
  }
}

@mixin team-table {
  &-team-table {
    table-layout: auto;
    text-align: left;
    width: 100%;
    color: $tt9-color-neutral-500;

    &-container {
      max-height: 100%;
      overflow-y: auto;
    }

    th {
      z-index: 3;
      border: none !important;
      border: 1px solid $tt9-color-neutral-200;
      border-width: 0 0 1px 0;
    }

    th,
    td {
      padding: $size-2;
      background: $tt9-color-neutral-0;
      vertical-align: middle;
      box-sizing: border-box;
    }

    .row-sticky {
      position: sticky;
      top: 0;
      z-index: 2;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 34px;
        width: 100%;
        border-bottom: 1px solid $tt9-color-neutral-200;
      }
    }

    &-row-active {
      td {
        background: $tt9-color-primary-100 !important;
      }
    }
  }
}
