$arrow-size: 8px;
$arrow-gap: calc($arrow-size / 2);

@mixin base-arrow-tooltip {
  overflow: visible !important;
  padding: 6px $size-2 !important;
  margin: 6px;
  background-color: $tt9-color-neutral-900 !important;
  min-width: $size-3;
  border-radius: $size-1;

  &::after {
    width: $arrow-size;
    height: $arrow-size;
    content: "";
    position: absolute;
    background-color: $tt9-color-neutral-900;
    border-radius: 2px 0 0 0;
    transform: rotate(45deg);
  }

  .mdc-tooltip__surface {
    background-color: inherit !important;
    min-width: $size-3 !important;
    max-width: 250px !important;
    min-height: unset !important;
    max-height: unset !important;
    padding: 0;
    font-family: $tt9-font-primary !important;
    font-size: 12px !important;
    line-height: 16px !important;
    will-change: auto;
  }
}

@mixin arrow-tooltip {
  &-arrow-tooltip {
    &-below,
    &-below-left,
    &-below-right,
    &-above,
    &-above-left,
    &-above-right,
    &-left,
    &-right {
      @include base-arrow-tooltip;

      &-large {
        @include base-arrow-tooltip;
        width: fit-content !important;
        margin-left: $size-5 !important;

        .mdc-tooltip__surface {
          max-width: 780px !important;
          word-break: break-all;
        }

        &-full {
          @include base-arrow-tooltip;
          width: fit-content !important;
          max-width: 1780px !important;

          .mdc-tooltip__surface {
            max-width: 1780px !important;
          }
        }
      }

      &-formatted {
        @include base-arrow-tooltip;
        white-space: pre;
        max-width: 300px !important;
        overflow: hidden !important;
      }
    }

    &-below-right {
      &::after {
        bottom: -$arrow-gap;
        right: calc(25% - $arrow-gap);
        border-radius: 0 0 2px 0;
      }
    }

    &-below,
    &-below-formatted,
    &-below-large,
    &-below-large-full {
      &::after {
        bottom: -$arrow-gap;
        right: calc(50% - $arrow-gap);
        border-radius: 0 0 2px 0;
      }
    }

    &-below-left {
      &::after {
        bottom: -$arrow-gap;
        left: calc(25% - $arrow-gap);
        border-radius: 0 0 2px 0;
      }
    }

    &-above,
    &-above-formatted,
    &-above-large,
    &-above-large-full {
      &::after {
        top: -$arrow-gap;
        right: calc(50% - $arrow-gap);
        border-radius: 2px 0 0 0;
      }
    }

    &-above-right {
      &::after {
        top: -$arrow-gap;
        right: calc(25% - $arrow-gap);
        border-radius: 2px 0 0 0;
      }
    }

    &-above-left,
    &-above-left-formatted,
    &-above-left-large,
    &-above-left-large-full {
      &::after {
        top: -$arrow-gap;
        right: calc(75% - $arrow-gap);
        border-radius: 2px 0 0 0;
      }
    }

    &-left {
      &:after {
        left: -$arrow-gap;
        top: calc(50% - $arrow-gap);
        border-radius: 0 0 0 2px;
      }
    }

    &-right {
      &:after {
        right: -$arrow-gap;
        top: calc(50% - $arrow-gap);
        border-radius: 0 2px 0 0;
      }

      &-wide {
        .mdc-tooltip__surface {
          max-width: 400px !important;
        }
      }
    }

    &-width-tooltip-400 {
      max-width: 400px !important;
      word-wrap: break-word;
    }
  }
}
