@mixin radio-button-template($color-shades, $border-hover-shade-index, $background-hover-shade-index) {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 3px;
  box-sizing: border-box;

  .mdc-radio {
    padding: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    --mdc-radio-state-layer-size: 0;

    .mat-ripple {
      display: none;
    }

    .mdc-radio__native-control {
      &:enabled + .mdc-radio__background {
        .mdc-radio__inner-circle {
          border-color: map-get($color-shades, "") !important;
        }
      }
    }

    &:not(.mdc-radio--disabled) {
      &:hover {
        .mdc-radio__background {
          .mdc-radio__inner-circle {
            border-color: map-get($color-shades, 500) !important;
            background-color: map-get($color-shades, 50) !important;
          }

          .mdc-radio__outer-circle {
            border-color: map-get($color-shades, $border-hover-shade-index) !important;
            background-color: map-get($color-shades, $background-hover-shade-index);
          }
        }
      }
    }

    &.mdc-radio--disabled {
      background-color: $tt9-color-neutral-50;
      &:hover {
        cursor: default;
      }

      .mdc-radio__native-control {
        cursor: default;
      }

      & + label {
        cursor: default !important;
      }

      .mdc-radio__background {
        .mdc-radio__inner-circle {
          background-color: transparent;
          border-color: $tt9-color-neutral-100;
          opacity: 1 !important;
        }

        .mdc-radio__outer-circle {
          opacity: 1 !important;
          border-color: $tt9-color-neutral-100 !important;
        }
      }
    }
  }

  .mdc-radio__background,
  .mat-mdc-radio-touch-target,
  .mdc-radio__native-control {
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }

  .mdc-radio__outer-circle {
    width: 18px;
    height: 18px;
    border-color: $tt9-color-neutral-400 !important;
  }

  .mdc-radio__inner-circle {
    width: 18px;
    height: 18px;
    border: 6px solid map-get($color-shades, "");
    background-color: transparent;
  }

  &.mat-mdc-radio-checked {
    .mdc-radio__inner-circle {
      transform: scale(1);
    }
  }
}
