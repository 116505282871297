@mixin font-style($font-size: 14px, $letter-spacing: 1px, $line-height: 1px, $font-weight: 400) {
  font-size: $font-size !important;
  font-weight: $font-weight !important;
  line-height: $line-height !important;
  letter-spacing: $letter-spacing !important;
}

.title {
  @include font-style(48px, 1px, 56px, 400);
}

.h1 {
  @include font-style(32px, 0, 48px, 700);
}

.h2 {
  @include font-style(28px, 0, 36px, 500);
}

.h3 {
  @include font-style(20px, 0.5px, 24px, 500);
}

.h4 {
  @include font-style(18px, 0.5px, 24px, 500);
}

.h5 {
  @include font-style(18px, -0.25px, 26px, 500);
}

.h6 {
  @include font-style(16px, 0.25px, 24px, 500);
}

.body {
  @include font-style(16px, 0, 24px, 400);
}

.body-small {
  @include font-style(14px, 0, 20px, 400);
}

.subtitle {
  @include font-style(14px, 0.25px, 16px, 500);
}

.assistive-title {
  @include font-style(12px, 1.5px, 16px, 500);
}

.assistive {
  @include font-style(12px, 0.15px, 18px, 400);
}
