@mixin navigation-tab {
  &-navigation-tab {
    padding: $size-1 $size-3;
    border-radius: 100px;
    background-color: transparent;
    border-color: transparent;
    color: $tt9-color-neutral;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover:not(.disabled):not(:disabled) {
      color: $tt9-color-accent;
    }

    &.active:not(.disabled):not(:disabled) {
      background-color: $tt9-color-accent;
      color: $tt9-color-neutral-0;
    }

    &.disabled {
      color: $tt9-color-neutral-300;
    }
  }
}
