@mixin navigation-list {
  &-navigation-list-header {
    .navigation-list-title {
      @extend .h6;
      border-bottom: 1px solid $color-neutral-600;
      padding: 24px 36px;
    }

    .inside-activity-navigation-title {
      @extend .assistive-title;
      @extend .text-uppercase;
      display: flex;
      align-items: center;
      padding: 16px 8px 0;
      color: $color-neutral-700;
    }
  }

  &-navigation-list-container {
    padding: 16px 0;

    .navigation-list-item-section,
    .navigation-list-item-session-section,
    .navigation-list-item-question,
    .navigation-list-item-activity,
    .navigation-list-item-slide {
      @include font-style(16px, 0, 24px, 400);
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 8px;

      .accenture-category-chip {
        margin-left: 16px;
        padding: 3px 7px !important;
      }

      .navigation-list-item-drop-down-icon {
        color: $color-neutral-700;
        margin-right: 4px;

        &.arrow-down {
          transition: all 0.25s ease-in-out;
          transform: rotate(90deg);
        }

        &.arrow-right {
          transition: all 0.25s ease-in-out;
          transform: rotate(0deg);
        }

        &.arrow-up {
          transition: all 0.25s ease-in-out;
          transform: rotate(-90deg);
        }

        &.right-side-item {
          margin-right: 0;
          margin-left: 4px;
        }
      }

      .navigation-list-visibility-icon,
      .navigation-list-menu-icon {
        color: $color-neutral-700;
      }

      .navigation-list-item-icon {
        font-size: 16px;
        height: 16px;
        width: 16px;
        color: $color-secondary;
        margin: 0 4px;
      }

      .navigation-list-menu-icon,
      .navigation-list-item-add-icon {
        visibility: hidden;
      }

      &:hover .navigation-list-menu-icon,
      &:hover .navigation-list-item-add-icon {
        visibility: visible;
      }

      &:hover {
        background-color: $color-neutral-300;
      }

      &.disabled-navigation {
        &:hover {
          cursor: default;
          background-color: transparent;
        }

        &.selected {
          background-color: $color-secondary-100;
        }
      }

      &.selected {
        background-color: $color-secondary-100;
        border: 1px solid $color-secondary-500;
      }

      .navigation-list-item-drop-down-icon,
      .navigation-list-menu-icon {
        &:hover {
          color: $color-neutral-800;
        }
      }

      &.disabled {
        opacity: 0.5;
        cursor: default;
      }
    }

    .navigation-list-item-session-section {
      background-color: $color-neutral-500;
    }

    .navigation-list-item-label {
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .navigation-list-item-index {
      display: flex;
      align-items: flex-start;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 4px;
      color: $color-neutral-700;
    }

    .navigation-list-item-image {
      width: 264px;
      height: 148px;
      border-radius: $border-radius;
      object-fit: cover;
      border: 2px solid $color-neutral-600;
      box-sizing: border-box;
      flex-shrink: 0;

      &.selected,
      &:hover {
        background-color: transparent;
        border: 2px solid $color-secondary-500;
      }

      &.inside-activity-navigation-image {
        width: 188px;
        height: 124px;
      }
    }

    .navigation-list-item-question,
    .navigation-list-item-activity {
      padding-left: 36px;

      &.selected {
        padding: 7px 7px 7px 35px;
      }
    }

    .navigation-list-item-slide {
      align-items: flex-start;
      justify-content: flex-end;
      padding: 4px 24px;

      &.selected,
      &:hover {
        background-color: inherit;
      }
    }

    .navigation-list-item-session-section,
    .navigation-list-item-activity {
      .navigation-list-item-index {
        @extend .assistive;
        margin-right: 0;
        color: $color-neutral-900;
      }
    }

    .navigation-list-item-with-separate-blocks {
      display: flex;
      justify-content: space-between;
    }

    .navigation-list-item-actions {
      margin-left: auto;
      display: flex;
    }
  }
}
