@import "variables";

@mixin chart {
  &-chart {
    &-figure {
      position: relative;
      font-size: 0;
    }
  }
}
