@mixin checkbox {
  &-checkbox.mat-mdc-checkbox {
    display: flex;
    height: 24px;
    box-sizing: border-box;

    .mdc-checkbox {
      width: 18px;
      height: 18px;
      //it is variable of theme, need to override default margins of checkbox
      margin: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 2 + -8px);

      .mat-mdc-checkbox-touch-target {
        width: 24px;
        height: 24px;
      }
    }

    .mdc-form-field .mdc-label {
      line-height: 20px;
      margin-left: $size-1;
      font-family: $tt9-font-primary;
      font-size: $size-3;
    }

    &.select-all {
      .mdc-checkbox {
        width: 16px;
        height: 16px;
      }

      .mdc-checkbox__background {
        background: $tt9-checkbox-select-all;
        border-radius: 2px;
        border-color: $tt9-checkbox-select-all;
      }

      .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
        background: $tt9-color-neutral-0;
        border: 2px solid $tt9-color-neutral-400;
        border-radius: 2px;
        border-color: $tt9-color-neutral-400;
      }
    }

    .mdc-form-field {
      align-items: center;
    }

    .mdc-checkbox__ripple,
    .mat-mdc-checkbox-ripple {
      display: none;
    }

    .mdc-checkbox__background {
      border-radius: 4px;
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
      background-color: $tt9-color-primary-300;
    }

    &:not(.mat-mdc-checkbox-checked) {
      .mdc-checkbox__background {
        border-color: $tt9-color-neutral-400 !important;
      }
    }

    &:not(.mat-mdc-checkbox-checked):not(.select-all) {
      &:not(.mat-mdc-checkbox-disabled) {
        .mdc-checkbox:hover {
          .mdc-checkbox__background {
            border-color: $tt9-color-primary-200 !important;
            background-color: $tt9-color-primary-50 !important;
          }
        }
      }
    }

    &.mat-mdc-checkbox-disabled {
      .mdc-checkbox {
        .mdc-checkbox__background {
          background-color: $tt9-color-neutral-50 !important;
        }
      }
    }

    &.mat-mdc-checkbox-checked.mat-primary {
      &:not(.mat-mdc-checkbox-disabled) {
        .mdc-checkbox:hover {
          .mdc-checkbox__background {
            background-color: $tt9-color-primary-500 !important;
            border-color: transparent;
          }
        }
      }

      &.mat-mdc-checkbox-disabled {
        .mdc-checkbox__background {
          background-color: $tt9-color-neutral-500 !important;
        }
      }
    }

    &.width-auto {
      width: auto;
    }
  }
}
