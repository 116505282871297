// ------ view
$view-padding: $size-4;
$view-width: 100vw;
$view-height: 100vh;

$mobile-view-padding: $size-2;
// ------ /view

// ------ content
// margin and padding
$content-margin: $size-2 $size-4 $size-4;
$content-padding: $size-3 $size-3 0;

// toolbar
$content-toolbar-width: 40px;
$content-toolbar-gap: 16px;

// agenda
$content-agenda-width: 296px;
$content-agenda-width-collapsed: 56px;
$content-agenda-width-handset: 296px;
$content-agenda-width-handset-landscape: 390px;
$content-agenda-width-small-handset-landscape: 320px;
$content-agenda-gap: 20px;
$content-agenda-header: 56px;

// tooltip
$content-tooltip-width: 288px;

//chat-bot
$content-chat-bot-width: 400px;

// width
$content-width: calc($view-width - 2 * $view-padding);
$content-inner-width: calc($content-width - 2 * $size-3);
$content-inner-width-without-toolbar: calc($content-inner-width - $content-toolbar-width - $content-toolbar-gap);
$content-inner-width-without-agenda: calc($content-inner-width - $content-agenda-width - $content-agenda-gap);
$content-inner-width-without-agenda-and-toolbar: calc(
  $content-inner-width-without-agenda - $content-toolbar-width - $content-toolbar-gap
);

// height
$content-height: calc($view-height - (2 * $view-padding) - $header-height - $header-offset);
$content-inner-height: calc($content-height - $size-3);

$mobile-content-height: calc($view-height - (2 * $mobile-view-padding) - $mobile-header-height - $header-offset);
$handsets-content-height: calc($view-height - $mobile-header-height);

// border-radius
$content-border-radius: 10px;
// ------ /content
