@mixin font-style($font-size: 14px, $letter-spacing: 1px, $line-height: 1px, $font-weight: 400) {
  font-size: $font-size !important;
  font-weight: $font-weight !important;
  line-height: $line-height !important;
  letter-spacing: $letter-spacing !important;
}

@mixin typography {
  &-activity-title {
    @include font-style(48px, -0.3px, 56px, 600);
  }

  &-h1 {
    @include font-style(36px, -0.3px, 42px, 500);
  }

  &-h2 {
    @include font-style(28px, 0px, 32px, 500);
  }

  &-h3 {
    @include font-style(24px, 0px, 28px, 500);
  }

  &-h4 {
    @include font-style(20px, 0px, 24px, 400);
  }

  &-h4-medium {
    @include font-style(20px, 0px, 24px, 500);
  }

  &-h5 {
    @include font-style(18px, 0.2px, 22px, 400);
  }

  &-h6 {
    @include font-style(16px, 0.2px, 20px, 400);
  }

  &-body-1 {
    @include font-style(16px, 0px, 20px, 400);
  }

  &-body-medium-1 {
    @include font-style(16px, 0px, 20px, 500);
  }

  &-body-2 {
    @include font-style(14px, 0px, 18px, 400);
  }

  &-body-medium-2 {
    @include font-style(14px, 0px, 18px, 500);
  }

  &-link {
    @extend .tt9-body-1;
    color: $tt9-color-primary;
    cursor: pointer;

    &:visited {
      color: $tt9-color-primary;
    }

    &:hover {
      color: $tt9-color-primary-400;
    }

    &:active {
      color: $tt9-color-primary-500;
    }

    &:disabled,
    &.disabled {
      pointer-events: none;
      color: $tt9-color-neutral-400;
    }
  }

  &-button-text-large {
    @include font-style(20px, 0px, 24px, 500);
  }

  &-button-text-medium {
    @include font-style(14px, 0.3px, 20px, 500);
  }

  &-button-text-small {
    @include font-style(14px, 0.3px, 18px, 500);
  }

  &-caption-1 {
    @include font-style(12px, 0.2px, 16px, 400);
  }

  &-caption-2 {
    @include font-style(12px, 0px, 16px, 500);
    text-transform: uppercase;
  }

  &-caption-3 {
    @include font-style(10px, 0px, 14px, 500);
    text-transform: uppercase;
  }

  &-categories {
    @include font-style(14px, 1px, 18px, 500);
    text-transform: uppercase;
  }

  &-column-title {
    @include font-style(12px, 0px, 16px, 500);
    text-transform: uppercase;
  }

  &-input-label {
    @include font-style(12px, 0px, 14px, 400);
  }

  &-helper-text {
    @include font-style(12px, 0px, 14px, 400);
  }

  &-input-text {
    @include font-style(16px, 0px, 20px, 400);
  }

  &-input-text-small {
    @include font-style(14px, 0px, 18px, 400);
  }

  &-assistive {
    @include font-style(12px, 0px, 14px, 400);
  }

  &-tooltip {
    @include font-style(10px, 0.2px, 14px, 500);
  }

  &-mat-error {
    @extend .tt9-helper-text;
    color: $tt9-color-error-400 !important;
    margin-top: $size-2;
  }

  @include screen-all-mobiles() {
    &-activity-title {
      @include font-style(32px, -0.3px, 40px, 600);
    }

    &-h1 {
      @include font-style(28px, -0.3px, 36px, 500);
    }

    &-h2 {
      @include font-style(24px, 0px, 28px, 500);
    }

    &-h3 {
      @include font-style(22px, 0px, 26px, 500);
    }

    &-h4 {
      @include font-style(20px, 0px, 24px, 400);
    }

    &-h4-medium {
      @include font-style(20px, 0px, 24px, 500);
    }
  }
}
