$popup-media-width: 815px;
$popup-media-height: 450px;

@mixin files-list {
  &-files-list {
    padding-bottom: 16px;

    &.underlined-list {
      margin-bottom: 24px;
      padding-bottom: 12px;
      border-bottom: 1px solid $color-neutral-600;
    }
  }

  &-file {
    width: 100%;
    max-width: 825px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-neutral-200;
    border-radius: $border-radius;
    padding: 8px 8px 8px 16px;
    margin-right: 24px;
    box-sizing: border-box;

    &-container {
      display: flex;
      align-items: flex-start;
      margin-bottom: 16px;
    }

    &-preview-image-container {
      min-height: 104px;
      min-width: 180px;
      background-color: $color-neutral-600;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: $border-radius-small;
      position: relative;
      cursor: pointer;
      margin-right: 16px;

      &.image-placeholder {
        background-size: initial !important;
        background-image: url("/assets/activity-shared-ui/svg/icon-image.svg") !important;
      }

      &.video-placeholder {
        background-size: initial !important;
        background-image: url("/assets/activity-shared-ui/svg/icon-videocam.svg") !important;
      }

      .overlay {
        @extend .assistive;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius-small;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: 0.5s ease;
        background-color: rgba($color-neutral-900, 0.2);
        color: $color-neutral-100;
        text-decoration: none;
      }

      img {
        border-radius: $border-radius-small;
        width: 100%;
        height: 100%;
      }

      &:hover {
        .overlay,
        .image-container-remove-btn {
          opacity: 1;
        }
      }
    }

    &-text-input {
      width: 100%;
      max-width: 825px;
      margin: -8px 24px 0 0;
    }

    &-placeholder {
      color: $color-secondary-500;
    }
  }

  &-popup-image {
    max-height: inherit;
    max-width: $popup-media-width;
  }

  &-popup-iframe {
    height: $popup-media-height;
    width: $popup-media-width;
  }
}
