@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "tt9-theme/_variables.scss";

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: $tt9-font-primary;
  color: $tt9-color-neutral;
  background: $tt9-color-neutral-50;
  //For iOS
  //Disables text scaling in horizontal orientation.
  -webkit-text-size-adjust: none;
}
