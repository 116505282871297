@mixin category-chip {
  &-category-chip {
    padding: 4px 8px !important;
    border: 1px solid $color-neutral-600 !important;
    background-color: $color-secondary-100 !important;
    box-sizing: border-box;
    border-radius: 3px !important;
    transition: all 0.3s;
    height: auto !important;
    width: fit-content;

    @include font-style(14px, 2px, 18px, 500);
    text-transform: uppercase;

    .mat-mdc-chip-remove {
      &:hover:not(.disabled),
      &:hover:not(:disabled) {
        background: $color-neutral-700;
        border-radius: 50%;
      }
    }

    &::after {
      display: none;
    }

    &:active {
      box-shadow: none !important;
    }

    &:not(.static):not(.disabled):not(:disabled) {
      &:hover {
        border-color: $color-secondary-500 !important;
      }

      &:active,
      &.selected {
        border-color: $color-secondary-500 !important;
        background-color: $color-secondary-200 !important;
      }
    }

    &:disabled,
    &.disabled {
      color: $color-neutral-700 !important;
      border-color: $color-neutral-700 !important;
      background-color: $color-neutral-400 !important;

      .mat-mdc-chip-remove {
        cursor: default;
        pointer-events: none;
        color: $color-neutral-700 !important;

        &:hover {
          background: transparent;
        }
      }

      .accenture-status {
        color: $color-neutral-700;
      }
    }
  }

  &-chip-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .accenture-status {
      margin-left: -4px;
      margin-bottom: -2px;
    }

    .accenture-text {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
}
