// deprecated
@mixin screen-pre-large-desktop {
  @media screen and (max-width: $screen-large-desktop) {
    @content;
  }
}

// deprecated
@mixin screen-pre-medium-desktop {
  @media screen and (max-width: $screen-medium-desktop) {
    @content;
  }
}

// > 1920
@mixin screen-x-large-desktop {
  @media screen and (min-width: $screen-x-large-desktop) {
    @content;
  }
}

// 1600-1919
@mixin screen-large-desktop {
  @media screen and (min-width: $screen-large-desktop) and (max-width: calc(#{$screen-x-large-desktop} - 1px)) {
    @content;
  }
}

// > 1440
@mixin screen-x-medium-desktop {
  @media screen and (min-width: $screen-medium-desktop) {
    @content;
  }
}

// 1440-1599
@mixin screen-medium-desktop {
  @media screen and (min-width: $screen-medium-desktop) and (max-width: calc(#{$screen-large-desktop} - 1px)) {
    @content;
  }
}

// 1280-1439
@mixin screen-small-desktop {
  @media screen and (min-width: $screen-small-desktop) and (max-width: calc(#{$screen-medium-desktop} - 1px)) {
    @content;
  }
}

// 768-1279 portrait and 1024-1279 landscape
@mixin screen-tablet {
  @media screen and (min-width: $screen-tablet-portrait) and (max-width: calc(#{$screen-small-desktop} - 1px)) and (orientation: portrait),
    screen and (min-width: $screen-tablet-landscape) and (max-width: calc(#{$screen-small-desktop} - 1px)) and (orientation: landscape) {
    @content;
  }
}
// 768-1279 portrait
@mixin screen-tablet-portrait {
  @media screen and (min-width: $screen-tablet-portrait) and (max-width: calc(#{$screen-small-desktop} - 1px)) and (orientation: portrait) {
    @content;
  }
}

// 1024-1279 landscape
@mixin screen-tablet-landscape {
  @media screen and (min-width: $screen-tablet-landscape) and (max-width: calc(#{$screen-small-desktop} - 1px)) and (orientation: landscape) {
    @content;
  }
}

// 390-767 portrait and 844-1023 landscape
@mixin screen-handset {
  @media screen and (min-width: $screen-handset-portrait) and (max-width: calc(#{$screen-tablet-portrait} - 1px)) and (orientation: portrait),
    screen and (min-width: $screen-handset-landscape) and (max-width: calc(#{$screen-tablet-landscape} - 1px)) and (orientation: landscape) {
    @content;
  }
}

// 390-767 portrait
@mixin screen-handset-portrait {
  @media screen and (min-width: $screen-handset-portrait) and (max-width: calc(#{$screen-tablet-portrait} - 1px)) and (orientation: portrait) {
    @content;
  }
}

// 844-1023 landscape
@mixin screen-handset-landscape {
  @media screen and (min-width: $screen-handset-landscape) and (max-width: calc(#{$screen-tablet-landscape} - 1px)) and (orientation: landscape) {
    @content;
  }
}

// < 389 portrait and < 843 landscape
@mixin screen-small-handset {
  @media screen and (min-width: $screen-small-handset-portrait) and (max-width: calc(#{$screen-handset-portrait} - 1px)) and (orientation: portrait),
    screen and (min-width: $screen-small-handset-landscape) and (max-width: calc(#{$screen-handset-landscape} - 1px)) and (orientation: landscape) {
    @content;
  }
}

// < 389 portrait
@mixin screen-small-handset-portrait {
  @media screen and (min-width: $screen-small-handset-portrait) and (max-width: calc(#{$screen-handset-portrait} - 1px)) and (orientation: portrait) {
    @content;
  }
}

// < 843 landscape
@mixin screen-small-handset-landscape {
  @media screen and (min-width: $screen-small-handset-landscape) and (max-width: calc(#{$screen-handset-landscape} - 1px)) and (orientation: landscape) {
    @content;
  }
}

// < 767 portrait and < 1023 landscape
@mixin screen-all-handset {
  @media screen and (max-width: calc(#{$screen-tablet-portrait} - 1px)) and (orientation: portrait),
    screen and (max-width: calc(#{$screen-tablet-landscape} - 1px)) and (orientation: landscape) {
    @content;
  }
}

// < 767 portrait
@mixin screen-all-handset-portrait {
  @media screen and (min-width: $screen-small-handset-portrait) and (max-width: calc(#{$screen-tablet-portrait} - 1px)) and (orientation: portrait) {
    @content;
  }
}

// < 1023 landscape
@mixin screen-all-handset-landscape {
  @media screen and (min-width: $screen-small-handset-landscape) and (max-width: calc(#{$screen-tablet-landscape} - 1px)) and (orientation: landscape) {
    @content;
  }
}

// < 1279 portrait and < 1279 landscape
@mixin screen-all-mobiles {
  @media screen and (max-width: calc(#{$screen-small-desktop} - 1px)) {
    @content;
  }
}

// < 1279 portrait
@mixin screen-all-mobiles-portrait {
  @media screen and (min-width: $screen-small-handset-portrait) and (max-width: calc(#{$screen-small-desktop} - 1px)) and (orientation: portrait) {
    @content;
  }
}

// < 1279 landscape
@mixin screen-all-mobiles-landscape {
  @media screen and (min-width: $screen-small-handset-landscape) and (max-width: calc(#{$screen-small-desktop} - 1px)) and (orientation: landscape) {
    @content;
  }
}

// > 1280 portrait and > 1280 landscape
@mixin screen-all-desktops {
  @media screen and (min-width: $screen-small-desktop) {
    @content;
  }
}

@mixin screen-custom-range($max-width: $screen-size-4, $min-width: $screen-size-10) {
  @media screen and (max-width: #{$max-width}) and (min-width: #{$min-width}) {
    @content;
  }
}

@mixin screen-custom-max($max-width: $screen-size-4) {
  @media screen and (max-width: #{$max-width}) {
    @content;
  }
}
