@mixin badges {
  &-badge {
    @extend .tt9-caption-1;

    display: flex;
    justify-content: center;
    padding: $size-1 $size-2;
    border-radius: 100px;
    color: $tt9-color-neutral-0;
    cursor: default;

    &.templates,
    &.publicSessionTemplates,
    &.sessions {
      background-color: map-get($tt9-accent-colors, 8, 1);
    }

    &.collections,
    &.projectTemplates,
    &.publicProjectTemplates,
    &.projects,
    &.active {
      background-color: map-get($tt9-accent-colors, 3, 1);
    }

    &.draft {
      background-color: $tt9-color-accent-300;
    }

    &.unsaved {
      background-color: map-get($tt9-accent-colors, 2, 1);
      color: $tt9-color-neutral;
    }

    &.published {
      background-color: map-get($tt9-accent-colors, 4, 1);
    }

    &.featured {
      background-color: map-get($tt9-accent-colors, 7, 1);
    }

    &.active-user {
      background-color: $tt9-color-success-500;
    }

    &.active-session {
      background-color: $tt9-color-success-500;
    }

    &.draft-session {
      background-color: $tt9-color-accent;
    }

    &.inactive-user {
      background-color: $tt9-color-neutral-100;
      color: $tt9-color-neutral;
    }

    &.deactivated-user {
      background-color: map-get($tt9-accent-colors, 0, 1);
    }

    &.activities,
    &.activityTemplates,
    &.publicActivityTemplates,
    &.activity {
      background-color: map-get($tt9-accent-colors, 6, 1);
    }
  }
}
