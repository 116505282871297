@mixin files-list {
  &-files-list {
    padding-top: $size-1;
    font-family: $tt9-font-primary;
  }

  &-file {
    @extend .d-flex-center-between;

    padding-bottom: $size-1;
    width: 100%;
    word-break: break-word;

    &-container {
      display: flex;
      align-items: flex-start;
    }

    a {
      color: $tt9-color-primary;
      text-decoration: none;
    }
  }
}
