@mixin button {
  border-radius: $border-radius;
  font-family: $tt9-font-primary;
  padding: 10px 15px;
  border: 1px solid transparent;
  transition: all 0.3s;
  display: flex;

  @include font-style(14px, 0.3px, 18px, 500);
  text-align: center;
  white-space: nowrap;

  &.full-width {
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  &:not(:disabled):not(.disabled):hover {
    cursor: pointer;
  }

  mat-icon {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@mixin button-icon {
  @include button;
  padding: 7px;

  mat-icon {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.btn-icon {
    align-items: center;
    padding: 7px 15px;
    gap: 8px;
  }

  &.btn-without-padding {
    padding: 0 !important;
    margin: 0 !important;
  }

  &.btn-without-border {
    border: none !important;
  }
}

@mixin button-text {
  @include button;
  padding-left: 0;
  padding-right: 0;

  span {
    padding: 0;
  }

  mat-icon {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }
}

@mixin button-filled(
  $button-color: $tt9-color-primary-shades,
  $primary-shade: "",
  $hover-shade: 400,
  $active-shade: 500,
  $text-color: $tt9-color-neutral-0
) {
  background-color: map-get($button-color, $primary-shade);
  border-color: map-get($button-color, $primary-shade);
  color: $text-color;

  &:hover:not(:disabled) {
    background-color: map-get($button-color, $hover-shade);
    border-color: map-get($button-color, $hover-shade);
    color: $text-color;
  }

  &:active:not(:disabled) {
    background-color: map-get($button-color, $active-shade);
    border-color: map-get($button-color, $active-shade);
  }

  &:disabled {
    background-color: $tt9-color-neutral-50;
    border-color: $tt9-color-neutral-50;
    color: $tt9-color-neutral-300;
  }
}

@mixin button-filled-secondary(
  $button-color: $tt9-color-primary-shades,
  $primary-shade: "",
  $hover-shade: 100,
  $active-shade: 200,
  $background-color: $tt9-color-neutral-50
) {
  background-color: $background-color;
  border-color: $background-color;
  color: map-get($button-color, $primary-shade);

  &:hover:not(:disabled) {
    background-color: map-get($button-color, $hover-shade);
    border-color: map-get($button-color, $hover-shade);
    color: map-get($button-color, $primary-shade);
  }

  &:active:not(:disabled) {
    background-color: map-get($button-color, $active-shade);
    border-color: map-get($button-color, $active-shade);
    color: map-get($button-color, $primary-shade);
  }

  &:disabled {
    background-color: $tt9-color-neutral-50;
    border-color: $tt9-color-neutral-50;
    color: $tt9-color-neutral-300;
  }

  &.active {
    background-color: map-get($button-color, "");
    border-color: map-get($button-color, "");
    color: $tt9-color-neutral-0;
  }
}

@mixin button-outline(
  $button-color: $tt9-color-primary-shades,
  $primary-shade: "",
  $hover-shade: 800,
  $active-shade: 900
) {
  font-weight: 400 !important;
  background-color: transparent;
  border-color: map-get($button-color, $primary-shade) !important;
  color: map-get($button-color, $primary-shade);

  span {
    font-weight: 400 !important;
  }

  &:hover:not(.disabled):not(:disabled) {
    border-color: map-get($button-color, $hover-shade) !important;
    color: map-get($button-color, $hover-shade);
  }

  &:active:not(.disabled):not(:disabled) {
    border-color: map-get($button-color, $active-shade) !important;
    color: map-get($button-color, $active-shade);
  }

  &:disabled {
    border-color: $tt9-color-neutral-50 !important;
    color: $tt9-color-neutral-300;
  }
}

@mixin button-ghost(
  $button-color: $tt9-color-primary-shades,
  $primary-shade: "",
  $hover-shade: 800,
  $active-shade: 900
) {
  font-weight: 400 !important;
  background-color: transparent;
  border-color: transparent;
  color: map-get($button-color, $primary-shade);

  span {
    font-weight: 400 !important;
  }

  &:hover:not(.disabled):not(:disabled) {
    color: map-get($button-color, $hover-shade);
  }

  &:active:not(.disabled):not(:disabled) {
    color: map-get($button-color, $active-shade);
  }

  &:disabled {
    color: $tt9-color-neutral-300;
  }
}
