@mixin drag-preview {
  &-drag-preview {
    display: block;
    background-color: $color-secondary-500;
    border-radius: 32px;
    padding: 8px;
    color: $color-neutral-100;
    padding: 10px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .label-number {
      border-radius: 50%;
      color: $color-secondary-500;
      text-align: center;
      padding: 2px;
      height: 20px;
      width: 20px;
      margin-right: 4px;
      display: inline-block;
      background-color: $color-neutral-100;
      box-sizing: border-box;
    }
  }
}
