$border-radius-small: 4px;
$border-radius: 8px;
$border-radius-medium: 16px;

// modal
// header and footer height
$boundary-height: 72px;

// image
$medium-image-width: 80px;
$medium-image-height: 80px;

$small-image-width: 40px;
$small-image-height: 40px;
