@mixin tabs {
  &-tabs {
    position: relative;
    padding: 0;
    overflow: auto;
    display: flex;
    box-sizing: border-box;

    &:after {
      content: "";
      position: absolute;
      bottom: 1px;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $tt9-color-neutral-200;
      z-index: 1;
    }

    &-tab {
      margin: $size-1 $size-2 $size-3;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      white-space: nowrap;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .label {
        padding-left: $size-2;
        color: $tt9-color-neutral-500;
      }

      &.active,
      &:hover {
        position: relative;
        color: $tt9-color-primary;

        .label {
          color: $tt9-color-neutral;
        }

        &:before {
          content: "";
          position: absolute;
          bottom: -15px;
          height: 3px;
          left: 0;
          right: 0;
          z-index: 2;
          background-color: $tt9-color-primary;
        }
      }
    }
  }
}
