@mixin modal {
  &-modal {
    mat-dialog-container {
      &.mat-mdc-dialog-container {
        padding: 0 !important;
        border-radius: 16px !important;
        background: inherit !important;
        box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
          0px 9px 46px 8px rgba(0, 0, 0, 0.12);

        .mdc-dialog__container {
          .mdc-dialog__surface {
            border-radius: 16px;
            box-shadow: none;
          }
        }

        .dialog-container {
          height: 100%;
          max-height: calc(100vh - $size-6);
          display: flex;
          flex-direction: column;
          overflow: hidden;

          .accenture-dialog-header {
            display: flex;
            align-items: center;
            padding: $size-3;
            box-sizing: border-box;
            height: $boundary-height;
            border-color: $tt9-color-neutral-200;
            color: $tt9-color-neutral;

            &.with-description {
              padding: $size-2 $size-3;
            }
          }
        }
      }
    }

    @include screen-tablet-portrait {
      mat-dialog-container {
        &.mat-mdc-dialog-container {
          .dialog-container {
            max-height: unset;
          }
        }
      }
    }
  }

  // "Add activity template to session" dialog
  // "Add session template to project" dialog
  &-add-template-dialog {
    width: 768px;
    height: calc($view-height - 136px); // by design, the modal has 68px distance from top and bottom
    overflow: hidden;

    .template-search {
      margin-bottom: $size-4;
    }

    &-header {
      padding: $size-3;
      height: 72px;
      border-bottom: 1px solid $tt9-color-neutral-200;
      box-sizing: border-box;
    }

    &-body {
      padding: $size-5;
      padding-bottom: 0;
      overflow: hidden;
      height: calc(100% - 72px); // total screen height minus footer
      box-sizing: border-box;
    }

    &-footer {
      border-top: 1px solid $tt9-color-neutral-200;
      padding: $size-3;
      box-sizing: border-box;

      &-buttons {
        gap: $size-3;
      }
    }
  }
}
